import {
  loadModalPositionFromCache,
  saveModalPositionToCache,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/cache'

const MOUSE_LEFT_BUTTON = 0

const DRAGGABLE_AREA = 'm-popover__title'
export const draggablePopover = (popover) => {
  let windowProperties = loadModalPositionFromCache()
  let isDown = false

  /** @type {HTMLElement} realPopover*/
  const realPopover = popover.querySelector('.m-popover')

  popover.addEventListener('mousedown', function (e) {
    if (e.button !== MOUSE_LEFT_BUTTON) { return }
    const id = e.target.id
    if (id !== DRAGGABLE_AREA) { return }

    isDown = true
    windowProperties = {
      left: popover.offsetLeft - e.clientX,
      top: popover.offsetTop - e.clientY,
      width: realPopover.clientWidth,
      height: realPopover.clientHeight,
    }
  }, true)

  popover.addEventListener('mouseup', function () {
    isDown = false

    windowProperties = {
      left: parseInt(popover.style.left),
      top: parseInt(popover.style.top),
      width: realPopover.clientWidth,
      height: realPopover.clientHeight,
    }

    saveModalPositionToCache(windowProperties)
  }, true)

  document.addEventListener('mousemove', function (event) {
    if (!isDown) { return }

    const shiftX = event.clientX + windowProperties.left
    const shiftY = event.clientY + windowProperties.top

    popover.style.left = shiftX + 'px'
    popover.style.top = shiftY + 'px'
  })
}

import BaseTree from '../base_tree'

export default class DiseasesTree extends BaseTree {
  /**
   * builds tree
   */
  fancyData (data, lazy = false, selectedCats = false, forseSelectChild) {
    let categories = selectedCats || []
    let res = []
    let store = res
    let rootSelected = categories.includes(0)
    if (!lazy) {
      let root = {
        folder: true,
        expanded: true,
        data: { id: 0 },
        title: t('diagnoses'),
        children: [],
        selected: rootSelected,
      }
      res.push(root)
      store = root.children
    }
    data.catalogs.forEach(function (cat) {
      var selected = categories.includes(cat.id)
      if (forseSelectChild) {
        selected = true
      }
      if (rootSelected) {
        selected = true
      }
      let title
      if (cat.parent_id === 0) {
        title = cat.title
      } else {
        title = `${cat.info} ${cat.title}`
      }
      var el = {
        folder: true,
        title,
        key: 'c' + cat.id,
        lazy: true,
        selected,
        data: {
          id: cat.id,
          type: 'category',
        },
      }

      store.push(el)
    })

    return res
  }

  build (data, mountPoint, categories = false) {
    this.mountPoint = mountPoint
    let tree = this.fancyData(data, false, categories)
    $(this.mountPoint).fancytree({
      checkbox: (event, data) => data.node.folder,
      icon (event, data) {
        if (!data.node.folder) return false
      },
      // click: (event, data) => {
      //   const tt = data.targetType
      //   if (tt === 'icon' || tt === 'title') {
      //   //   data.node.toggleExpanded()
      //   // }//
      // },
      // dblclick: () => false,
      source: tree,
      selectMode: 2,
      select: this.selectNode.bind(this),
      lazyLoad: (e, data) => {
        let selectChildren = data.node.selected
        let result = this.container.get('api').diseases(data.node.data.id)
          .then((data) => {
            let res = this.fancyData(data, true, categories, selectChildren)

            return res
          })
        data.result = result
      },
    })
    this.tree.reload(tree)
    this._setConnectors()
  }

  /**
   * private
   */
  _postConstruct () {
    this._setMountPoint()
  }
}

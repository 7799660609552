<template>
  <div class="personal-data-editor-position-app">
    <m-input
      v-tooltip="t('position')"
      :value="position"
      :placeholder="t('position')"
      :m-fixed-height="false"
      @input="position = $event"
    />

    <reusable-local-n-s-i-list
      v-if="egiszModuleEnabled"
      v-tooltip="t('nsiProfession')"
      :value="nsiProfession"
      :placeholder="t('nsiProfession')"
      :dictionary="NSI.PROFESSIONS"
      :m-fixed-height="false"
      full-width
      @changeByLazy="nsiProfession = $event"
    />

    <input
      class="hidden"
      :name="`${prefix}[position]`"
      :value="position"
    >

    <input
      class="hidden"
      :name="`${prefix}[nsi_profession_id]`"
      :value="nsiProfession?.id"
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'
import { ICatalog } from '@/_declarations/ICatalog'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'

export default defineComponent({
  name: 'PersonalDataEditorPositionApp',

  components: { MInput, ReusableLocalNSIList },

  props: {
    defaultPosition: { type: String, default: null },
    defaultNsiProfession: { type: Object as PropType<ICatalog<number>>, default: null },
    prefix: { type: String, required: true },
  },

  data () {
    return {
      NSI: NSI_DICTIONARIES,
      egiszModuleEnabled: gon.application.egisz_module_enabled,
      position: null,
      nsiProfession: null,
    }
  },

  created () {
    this.position = this.defaultPosition
    this.nsiProfession = this.defaultNsiProfession
  },
})
</script>


export const getMultiPrintDocumentsUrl = (ids, config = {
  printNow: true,
}) =>
  Routes.multi_print_documents_path({
    action: 'multicheck_actions_handler',
    actions: 'print',
    controller: 'documents',
    ids,
    print_now: config.printNow,
  })

import BaseModal from './base'
import htmlHelper from '../../helpers/htmlHelper'
import reducersStorage from '../../reducers'
import {createStore, applyMiddleware} from 'redux'

export default class EmComplexMemberModal extends BaseModal {
  load () {
    this._components.editor.loadHtml(this.htmlInput.value)
    this._components.editor.loadData(this.dataInput.value)
    this._components.editor.repairVariables()
  }

  _open () {
    super._open()
    this._components.editor.run()
  }

  _save () {
    this.htmlInput.value = tinymce.activeEditor.getContent()
    this.dataInput.value = JSON.stringify(this._store.getState())
    super._save()
  }

  _bindEvents () {
    this.saveButton.onclick = this._save.bind(this)
    super._bindEvents()
  }
}

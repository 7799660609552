import { el, mount, setChildren } from 'redom'
import isArray from 'lodash/isArray'
import { InsertDynamicEntity, InsertFixEntity } from '../../../../events'
import { CONCLUSIONS, EXTENSIBLE_LIST, FIX_LIST } from '../../../../configuration/reducer_types'

export class MenuWindow {
  constructor () {
    this.el = el('div.contexted', {
      contenteditable: false,
      style: this.style,
    })
  }

  get style () {
    return {
      minWidth: '550px',
      maxWidth: '600px',
    }
  }
}

export const createHeader = (menu) => {
  menu.header = el('div.contexted-head__container.editor-mode')

  // menuHead.innerHTML = `${t('remember_position')}<br><span class="not-close-block">${t('not_close')}</span>`

  // SPAN
  const closeBnt = el('i.fad.fa-times.contexted-close')
  closeBnt.onclick = () => {
    menu.notClose = false
    menu.forceDelete()
  }

  // boolean optional checkbox
  const notCloseCheckBox = el('label', el('input.boolean.optional.checkbox', {
    type: 'checkbox',
    checked: menu.notClose,
    id: 'not-close',
    name: 'not-close',
    onclick (event) { menu.notClose = event.target.checked },
  }))

  const savePositonCheckBox = el('input.not-close__checkbox.checkbox', {
    type: 'checkbox',
    checked: menu.savePosition || false,
    id: 'save-position',
    name: 'save-position',
    onclick (event) { menu.savePosition = event.target.checked },
  })

  const menuHead = el('div.contexted-head.editor-mode#contexted-head')
  const notCloseContainer = el('div.contex-menu_not-close-container')
  const savePositionContainer = el('div.contex-menu_save-position-container')

  const notCloseLabel = el('span', t('not_close'))
  const savePositionLabel = el('span', t('remember_position'))

  setChildren(
    notCloseContainer,
    notCloseCheckBox, notCloseLabel
  )

  setChildren(
    savePositionContainer,
    savePositonCheckBox, savePositionLabel
  )

  setChildren(
    menuHead,
    notCloseContainer, savePositionContainer
  )

  setChildren(
    menu.header,
    menuHead, closeBnt
  )

  mount(menu.window, menu.header)
}

export class MenuItems {
  constructor (menu) {
    this.menu = menu
    this.entity = menu.entity
    this.dispatcher = this.menu.container.get('dispatcher')
    this.event = menu.event
    this.groups = this.entity.data.value
    this.notClose = menu.notClose

    if (!this.groups) {
      this.groups = [t('errors.missing_list_items')]
    }
    if (!this.groups.every((e) => isArray(e))) {
      this.groups = [this.groups]
    }
    this.el = el('ul.contexted_ul')
    this.groups.map((group, groupIndex) => {
      group.forEach((item) => {
        const li = el('li.contexted_li', item, {
          onmousedown (event) { window.application.form_builder.current.globalMap.currSelection = tinymce.activeEditor.selection.getSel().getRangeAt(0) },
          onclick: (event) => this._bindOnClick(event),
        })
        mount(this.el, li)
      })

      if (groupIndex >= this.groups.length - 1) { return }

      // group separator
      mount(this.el, el('li.contexted_li_separator'))
    })
    this.menu.moutnElement = this.el
  }

  /**
   * private check event
   */
  _getEvent () {
    let event = false
    switch (this.entity.type) {
      case EXTENSIBLE_LIST:
      case CONCLUSIONS:
        event = InsertDynamicEntity
        break
      case FIX_LIST:
        event = InsertFixEntity
        break
      default:
        throw new Error('undefind type')
    }

    return event
  }

  /**
   * @private
   */
  _bindOnClick (e) {
    const EventClass = this._getEvent()
    this.event = new EventClass(event.target.innerHTML, this.entity.element)
    this.dispatcher.dispatch(this.event)
    this.menu.container.get('editor').update()
    if (!this.menu.notClose) {
      this.menu.delete()
    }
    e.preventDefault()
  }
}

export class DiagnoseWindow {
  constructor (menu) {
    this.el = el('div')
    mount(this.el, new DiagnoseForm(menu))
  }
}

export class DiagnoseCheckBox {
  constructor (data) {
    this.id = `${data.name}-checkbox`
    this.checked = data.checked || false
    this.checkBox = el('input', {
      type: 'checkbox',
      name: data.name,
      id: this.id,
      checked: this.checked,
    })
    this.el = el(
      'div', {
        class: 'checkbox-container',
        style: {
          marginLeft: '5px',
        },
      },
      this.checkBox,
      el('label', {
        class: 'diangose_label',
        for: this.id,
        style: { marginLeft: '5px' },
      }, data.title)
    )
  }

  get value () {
    return this.checkBox.checked
  }
}

export class DiagnoseForm {
  constructor (cMenu) {
    this.cMenu = cMenu
    this.entity = cMenu.entity
    this.data = this.entity.data
    this.value = this.data.value
    this.item = this.data.item
    this.buildElements()
    const checkBoxContainer = el('div', { style: { margin: '8px 0 0 8px' } },
      this.first, this.chronical, this.prof
    )
    const block = el('div', { style: { margin: '10px', marginTop: 0 } },
      el('div', `${this.item.code_string}: ${this.item.title}`, {
        style: {},
        class: 'diagnose-form-diagnose-title',
      }),
      this.area,
      el('input', {
        type: 'submit',
        value: t('save'),
        class: 'btn btn-success',
      })
    )
    this.el = el('form', { class: 'diagnose-form' },
      checkBoxContainer, block
    )
    this.el.onsubmit = this.submit.bind(this)
  }

  buildElements () {
    this.first = new DiagnoseCheckBox({
      name: 'first',
      title: t('set_first'),
      checked: this.value.first,
    })
    this.chronical = new DiagnoseCheckBox({
      name: 'chronocal',
      title: t('chronical'),
      checked: this.value.chronical,
    })
    this.prof = new DiagnoseCheckBox({
      name: 'professional',
      title: t('professional'),
      checked: this.value.prof,
    })

    this.area = el('textarea', {
      placeholder: t('note'),
      value: this.value.text || '',
      style: { minHeight: '70px' },
      class: 'form-control',
    })
  }

  get formData () {
    return {
      text: this.area.value,
      first: this.first.value,
      chronical: this.chronical.value,
      prof: this.prof.value,
    }
  }

  /**
   * event - submit event
   */

  submit (event) {
    event.preventDefault()
    this.entity.update(this.formData)
    this.cMenu.delete()
  }
}

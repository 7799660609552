<template>
  <el-dialog
    width="450px"
    :visible.sync="visible"
    :title="t('change_clinic')"
    :close-on-click-modal="false"
  >
    <select-clinic
      :clinic-id="clinicId"
      :user-id="userId"
      select-view
      full-width
    />

    <div class="mt-05">
      <i class="fad fa-fw fa-info-circle" />
      <span class="prompt-notice font-size-12">{{ t('change_clinic_info') }}</span>
    </div>
  </el-dialog>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import SelectClinic from '@/vue_components/users/SelectClinic/SelectClinic'

export default {
  name: 'SelectClinicModal',
  components: { SelectClinic },
  props: {
    clinicId: PropsTypes.Number(),
    userId: PropsTypes.Number(),
  },

  data () {
    return {
      visible: true,
    }
  },
}
</script>

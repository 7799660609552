var fetch = window.fetch

class Api {
  constructor (container) {
    this.container = container
  }

  getRelationCategories (categories) {
    let url =
      `/protocol_entities/assign_categories?${$.param({
        categories,
      })}`

    return fetch(url, {
      credentials: 'same-origin',
    }).then((response) => response.json())
  }

  getAssignDiagnosesCat (categories) {
    let url =
      `/protocol_entities/mkb_categories?${$.param({
        categories,
      })}`

    return fetch(url, {
      credentials: 'same-origin',
    }).then((response) => response.json())
  }

  protocol_entities (type) {
    let url = this.container.get('config')[type].fetch

    return fetch(url, {
      credentials: 'same-origin',
    }).then((response) => response.json())
  }

  diseases (categoryId = false) {
    if (!categoryId) {
      categoryId = 0
    }
    let url = '/categories/diseases'

    return fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category_type: 2,
        id: categoryId,
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json()
      }

      return Promise.reject(response)
    })
  }

  /**
   * fetch entry attribute list
   */
  fetchEntryAttributes (url = false) {
    if (!url) {
      url = '/template_records/entry_attribute_list'
    }

    return fetch(url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        credentials: 'same-origin',
      },
    ).then((response) => {
      if (response.ok) {
        return response.json()
      }

      return Promise.reject(response)
    })
  }
}

export default Api

class Manager {
  constructor (props) {
    this._props = Object.assign({}, props)
    this.initManager()
  }

  initManager () {
    //create container with function
    this._fStore = {}
    this._fStore.elements = () => {
      return this._props.editorMountPoint.querySelectorAll('.t-element')
    }
    this._fStore.editor = () => {
      return tinymce.editors[this._props.editorId]
    }
  }

  /**
   * return prop value
   */
  get (propName) {
    if (this._props[propName]) {
      return this._props[propName]
    }

    if (this._fStore[propName]) {
      const propValue = this._fStore[propName]()

      return propValue
    }

    return null
  }

  /**
   *
   */
  set (propName, value) {}
}

export default Manager

const htmlHelper = {
  clearHtml (html = false) {
    const editElements = document.querySelectorAll('.t-element')
    if (editElements.length > 0) {
      editElements.forEach((item) => {
        item.classList.remove('t-element')
        item.classList.add('element')
        item.setAttribute('contenteditable', false)
      })
    }
    $('.diagnoses_constructor').hide()
    $('.tree-icon.control').hide()
  },

  restoreHtml () {
    const editElements = document.querySelectorAll('.element')
    if (editElements.length > 0) {
      editElements.forEach((item) => {
        item.classList.remove('element')
        item.classList.add('t-element')
      })
    }
    $('.diagnoses_constructor').show()
    $('.tree-icon.control').show()
  },
}

export default htmlHelper

import BaseTree from '../base_tree'
import Events from './events'

export default class AssignEntitiesTree extends BaseTree {
  /**
   * builds tree
   */
  build (tree, mountPoint, sourceElement) {
    this.mountPoint = mountPoint
    this.sourceElement = sourceElement
    this.nodes = tree[0].children.length + 1 //все узлы + главная папка
    $(this.mountPoint).fancytree({
      beforeExpand: (event, data) => data.node.key !== '_1',
      expand (event) {
        let menuHeight = parseInt($(event.target).parents('.contexted').css('height'))
        let windowHeight = window.innerHeight
        let Yposition = parseInt($(event.target).parents('.contexted').css('top'))
        let correction = menuHeight + Yposition - windowHeight
        if (correction > 0) {
          let newYposition = Yposition - correction > 0 ? Yposition - correction : 0
          $(event.target).parents('.contexted').css('top', newYposition)
        }
      },
      source: tree,
      selectMode: 2,
      icon: this.icon.bind(this),
      activate: this.activateNode.bind(this),
      click: this.onNodeClick.bind(this),
      dblclick: () => false,
      init (event, data) {
        data.tree.getRootNode().sortChildren(null, true)
      },
    })
    this.tree.reload(tree)
    this._setConnectors()
    this._setScroll()
    this._setPosition()
    this._hideRootExpander()
  }

  /**
   * private
   */

  _hideRootExpander () {
    this.mountPoint.find('.fancytree-expander').first().hide()
  }

  onNodeClick (event, data) {
    this.clickNode(event, data)
    if (data.node.folder && data.node.data.depth !== 0) {
      const targetType = $.ui.fancytree.getEventTargetType(event.originalEvent)
      if (targetType !== 'expander') {
        data.node.toggleExpanded()
      }
    }
  }

  _postConstruct () {
    (new Events()).pullEvents(this)
    this._setMountPoint()
  }
}

<template>
  <buttons-group
    v-if="isButtonsView"
    v-model="localClinicId"
    :items="clinics"
    attribute-value="id"
    attribute-label="title"
    orientation="vertical"
    class="clinics-buttons-group"
  />
  <simple-select
    v-else
    v-model="localClinicId"
    class="component clinic_handler"
    :items="clinics"
    :fixed-height="false"
    filterable
    full-width
  />
</template>

<script>
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'
import ButtonsGroup from '@/vue_components/common/ButtonsGroup/ButtonsGroup'
import { MAX_CLINICS_FOR_BUTTONS_VIEW } from '@/vue_components/users/SelectClinic/const'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SelectClinic',
  components: {
    SimpleSelect,
    ButtonsGroup,
  },

  props: {
    clinicId: PropsTypes.Number(),
    userId: PropsTypes.Number(),
    selectView: Boolean,
    fullWidth: Boolean,
  },

  data () {
    return {
      localClinicId: this.clinicId,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      clinics: 'vxGetDoctorClinics',
    }),

    isButtonsView () {
      if (this.selectView) { return false }

      return this.clinics && this.clinics.length <= MAX_CLINICS_FOR_BUTTONS_VIEW
    },
  },

  watch: {
    async localClinicId (newClinicId) {
      await doctorPresenter.selectClinic(this.userId, newClinicId)
    },
  },

  created () {
    this.fetchClinics()
  },

  methods: {
    ...mapActions('globalCatalogs/doctorStore', {
      vxLoadClinics: 'vxLoadClinics',
    }),

    async fetchClinics () {
      await this.vxLoadClinics(this.userId)
    },
  },
}
</script>

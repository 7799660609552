import Listener from './listener'

class BuildEntryVaiableModal extends Listener {
  /**
   * insert data to tinymce to fix element
   */
  handle (event) {
    this.container.get('entry_variables_modal').mountBody()
    this.container.get('entry_variables_modal').show()
  }
}

export default BuildEntryVaiableModal

<template>
  <div class="semd-protocol-editor">
    <div class="flex gap-indent-small align-center">
      <semd-template-record-watcher
        :is-template-records="isTemplateRecords"
        :semd-type="semdType"
        :selected-semd-entities="selectedSemdEntities"
        @semdTypeChange="setSemdType"
      />

      <template v-if="semdType">
        <semd-entity-select-wrapper
          :semd-type="semdType"
          :selected-semd-entities="selectedSemdEntities"
          :semd-entities="semdEntities"
          :form-builder-redux-store="formBuilderReduxStore"
          :editor="editor"
          :is-template-records="isTemplateRecords"
          @clear="onClear"
          @set-protocol-changed="notifyEnvironmentChanged"
        />

        <semd-entities-warning
          :semd-entities="semdEntities"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import { ConfirmationMixin } from '@/vue_present/mixins/ConfirmationMixin'
import { ISemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/ISemdEntity'
import { getSemdEntitiesBySemdType } from '@/vue_apps/Protocols/SemdProtocolEditor/const/getSemdEntitiesBySemdType'
import SemdEntitiesWarning
  from '@/vue_apps/Protocols/SemdProtocolEditor/components/SemdEntitySelectWrapper/SemdEntitiesWarning.vue'
import SemdEntitySelectWrapper from '@/vue_apps/Protocols/SemdProtocolEditor/components/SemdEntitySelectWrapper.vue'
import SemdTemplateRecordWatcher from '@/vue_apps/Protocols/SemdProtocolEditor/components/SemdTemplateRecordWatcher.vue'
import { FormBuilderReduxStore } from '@/vue_apps/Protocols/entities/reduxStore/FormBuilderReduxStore'
import { TTinyMCEEditor } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/TTinyMCE'
import { SEMD_ENTITIES_ACTIONS } from '@/vue_apps/Protocols/SemdProtocolEditor/reducers/semdEntitiesActions'
import { dropSemdAreasColor } from '@/vue_apps/Protocols/SemdProtocolEditor/const/dropSemdAreasColor'

export default defineComponent({
  name: 'SemdProtocolEditorApp',
  components: {
    SemdTemplateRecordWatcher,
    SemdEntitySelectWrapper,
    SemdEntitiesWarning,
  },

  mixins: [ConfirmationMixin],

  props: {
    editor: {
      type: Object as PropType<TTinyMCEEditor>,
      required: true,
    },
  },

  data () {
    return {
      semdType: null as keyof typeof SEMD_TYPE_ENUM,
      semdEntities: [] as ISemdEntity[],

      formBuilderReduxStore: null as FormBuilderReduxStore,
    }
  },

  computed: {
    isTemplateRecords () {
      return window.gon.page.controller === 'template_records'
    },

    selectedSemdEntities () {
      return this.semdEntities
        .filter((entity) => entity.element && !entity.disabled)
    },
  },

  created () {
    this.resetStore()
  },

  mounted () {
    setTimeout(() => { this.init() })
  },

  methods: {
    init () {
      this.linkWithExists()
      setTimeout(() => { this.syncWithStore() })
    },

    setSemdType (semdType: keyof typeof SEMD_TYPE_ENUM = null) {
      dropSemdAreasColor(this.editor)
      this.onClear(true)
      this.semdType = semdType
      this.fillSemdEntities()
      this.resetStore()
    },

    fillSemdEntities () {
      this.semdEntities = getSemdEntitiesBySemdType(this.semdType)
    },

    resetStore () {
      this.formBuilderReduxStore = new FormBuilderReduxStore()
    },

    linkWithExists () {
      this.semdEntities
        .filter((semdEntity) => !semdEntity.disabled)
        .forEach((semdEntity) => semdEntity.linkWithElement(this.editor))
    },

    syncWithStore () {
      this.selectedSemdEntities.forEach((semdEntities) => {
        this.formBuilderReduxStore.dispatch(
          SEMD_ENTITIES_ACTIONS.SET_VALUE,
          { field: semdEntities.id, value: semdEntities.value || true }
        )
      })
    },

    async onClear (skipConfirm = false) {
      if (!skipConfirm) {
        const { cancel } = await this.confirmDelete(window.t('semds.delete.egiszLayout'))
        if (cancel) { return }
      }

      this.selectedSemdEntities.forEach((semdEntity) => {
        semdEntity.destroy(this.editor)
      })

      this.formBuilderReduxStore.dispatch(SEMD_ENTITIES_ACTIONS.CLEAR)

      this.notifyEnvironmentChanged()
    },

    notifyEnvironmentChanged () {
      this.$pubSub.emit('setProtocolChanged')
    },
  },
})
</script>

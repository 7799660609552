import {
  BASE_WINDOW_HEIGHT,
  BASE_WINDOW_TOP,
  BASE_WINDOW_WIDTH,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/const'

export const saveModalPositionToCache = (data, key = 'protocolModalPosition') => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const loadModalPositionFromCache = (
  key = 'protocolModalPosition',
  offset = {
    top: BASE_WINDOW_TOP,
    left: document.documentElement.clientWidth / 2,
    width: BASE_WINDOW_WIDTH,
    height: BASE_WINDOW_HEIGHT,
  }
) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || offset
  } catch {
    return offset
  }
}

const SEARCH_TEXTS = [
  t('client_with_the_same_fio_phone_and_birthdate_exist'),
  t('client_with_the_same_name_and_phone_exist'),
]

export const pinText = t('client_with_the_same_fio_phone_and_birthdate_exist_pin')

/**
 * @param {String} text
 */
export const getMatchingText = (text) => {
  let result = ''
  SEARCH_TEXTS.forEach((st) => {
    if (text.indexOf(st) === -1) { return }
    result = st
  })

  return result
}

import { EDITOR_OPENED } from '@/plugins/dynamic_forms/reducers/editor_opened'
import { AUTOSAVE_HTML_BY_ENTRY_ID, AUTOSAVE_TEMPLATE_DATA_BY_ENTRY_ID } from '@/specific/entries/const'

const getKeysByEntryId = (entryId) => [
  AUTOSAVE_HTML_BY_ENTRY_ID(entryId),
  AUTOSAVE_TEMPLATE_DATA_BY_ENTRY_ID(entryId),
]

export const getSavedProtocol = (entryId) => {
  const [keyHtml, keyData] = getKeysByEntryId(entryId)

  return [localStorage.getItem(keyHtml), localStorage.getItem(keyData)]
}

export const setSavedProtocol = (entryId, html, data) => {
  const [keyHtml, keyData] = getKeysByEntryId(entryId)
  localStorage.setItem(keyHtml, html)
  localStorage.setItem(keyData, data)
}

export const removeSavedProtocolFromLocalStorage = (entryId) => {
  const [keyHtml, keyData] = getKeysByEntryId(entryId)
  localStorage.removeItem(keyHtml)
  localStorage.removeItem(keyData)
}

export const getReduxStore = () => window.application &&
  window.application.form_builder &&
  window.application.form_builder.frontend &&
  window.application.form_builder.frontend.store

export const getState = () => {
  const store = getReduxStore()
  if (!store) { return }

  return store.getState()
}

export const isEditorOpened = () => {
  const state = getState()
  if (!state) { return }

  return state[EDITOR_OPENED]
}

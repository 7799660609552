
export class SemdListSeparator {
  id: string

  title: string

  disabled = true

  isOptional = true

  constructor ({
    id,
    title,
  }) {
    this.id = id
    this.title = title
  }
}

import BaseModal from './base'
import PrintAreaManager from '../../entities/print_area_manager'
import {
  getPrintingToolsFromConfiguration,
  getPrintingToolsFromItem,
  getPrintingToolsValues,
} from '@/helpers/printing_tools'

export default class TemplateRecordModal extends BaseModal {
  _bindEvents () {
    super._bindEvents()
    // true new flag
    this.newItemButton.onclick = () => { this._open(true) }
    this.saveButton.onclick = this._save.bind(this)

    // map only entry_types page
    if (this.modalButton) {
      this.modalButton.onclick = this.openCompletedList.bind(this)
    }
    if (this.openButton) {
      this.openButton.onclick = this._openItem.bind(this)
    }
    if (this.unbindButton) {
      this.unbindButton.onclick = this._unbindTemplate.bind(this)
    }

    if (this.protocolSaveBtn) {
      this.protocolSaveBtn.onclick = this._saveConnectedProtocolId.bind(this)
    }

    if (this.categoriesListWrap) {
      this.categoriesListWrap.onclick = this._showNotify.bind(this)
    }

    $(this.modalWindow).bind('hide.bs.modal', (e) => {
      const canManage = Services.security.canManageTemplateRecord

      if (this.forceCloseTemplate || !this._diffTemplateRecord()) { return }
      if (!canManage) {
        this.forceCloseTemplate = true
        $(this.modalWindow).megamodal('hide')

        return
      }

      e.preventDefault()
      bootbox.confirmYN(t('save_changed_data?'), (res) => {
        if (!res) {
          this.forceCloseTemplate = true
          $(this.modalWindow).megamodal('hide')

          return
        }

        if (!PrintAreaManager.hasIllegalAreas()) {
          this._save()

          return
        }

        Notificator.info(t('wrong_print_area_position'))
      })
      $('.bootbox').css('zIndex', '10000000')
    })

    $(this.templateListModal).bind('hide.bs.modal', (e) => {
      if (
        !this.forceClose &&
        this.templateRecordIdInput.value !== this.recordIdInput.value
      ) {
        e.preventDefault()
        bootbox.confirmYN(t('save_changed_data?'), (res) => {
          if (res) {
            this._saveConnectedProtocolId()
          } else {
            this.forceClose = true
            $(this.templateListModal).megamodal('hide')
          }
        })
        $('.bootbox').css('zIndex', '10000000')
      }
    })
  }

  _diffTemplateRecord () {
    const form = $(this.editor.form)
    if (this.isNewTemplate) {
      return form.find('#template_record_title').val().trim() !== '' ||
      this.editorMountPoint.innerText.trim() !== ''
    }
    const data = this.itemTemplate

    return data.title !== form.find('#template_record_title').val() ||
      this.htmlInput.value !== this.editorMountPoint.innerHTML ||
      !this._isEquelBool(data.print_clinic_header, form.find('.template_record_print_clinic_header input').val()) ||
      !this._isEquelBool(data.print_entry_title, form.find('.template_record_print_entry_title input').val()) ||
      !this._isEquelBool(data.print_entry_info, form.find('.template_record_print_entry_info input').val()) ||
      !this._isEquelBool(data.print_doctor_signature, form.find('#print_doctor_signature_wrapper input').val())
  }

  _isEquelBool (a, b) {
    return Boolean(a) === Boolean(+b)
  }

  _showNotify () {
    if (this.categoryTreeContainer.classList.contains('disabled-block')) {
      Notificator.info(t('unbind_current_template'))
    }
  }

  _saveConnectedProtocolId () {
    this.templateRecordIdInput.value = this.recordIdInput.value
    $(this.protocolModal).megamodal('hide')
  }

  _unbindTemplate () {
    this.currentTemplateContainer.querySelector('.current-template-name').innerText = ''
    this.categoryTreeContainer.classList.remove('disabled-block')
    this.categoriesListWrap.style.cursor = 'inherit'
    this.recordIdInput.value = ''
    this.htmlInput.value = ''
    this.dataInput.value = ''
    this.templateListModal.find('.modal-body').css('overflowY', 'auto')
    $('#not-bind-template-info').show()
    $('#template-items-table').hide()
  }

  openCompletedList () {
    this.templateListModal.megamodal('show')
    this.templateListModal.find('.modal-footer').find('.modal-footer-left').remove()
    const currentId = $('#entry_type_template_record_id').val()

    if (currentId) {
      $.ajax({
        type: 'GET',
        url: Routes.template_record_path(currentId),
        success: (data) => this._loadCurrentTemplate(data),
      })
    } else {
      $('#not-bind-template-info').show()
      $('#template-items-table').hide()
      this.categoryTreeContainer.classList.remove('disabled-block')
      this.categoriesListWrap.style.cursor = 'inherit'
    }

    const categoryContainer = $('#template-records_category_list')
    categoryContainer.categoriesx({
      url: '/categories/template_records',
      editable: false,
      groupEdit: false,
      responsive: {
        offset: 205,
      },
      onClick: (item) => this._loadCurrentTemplate(item),
    })
  }

  _loadCurrentTemplate (item) {
    this.forceClose = false
    this.currentItem = item
    this.categoryTreeContainer.classList.add('disabled-block')
    this.categoriesListWrap.style.cursor = 'not-allowed'

    this.templateListModal.find('.modal-body').scrollTo(0, 0)
    this.currentTemplateContainer
      .querySelector('.current-template-name').innerText = item.title
    this.recordIdInput.value = item.id

    this.htmlInput.value = item.template_html
    this.dataInput.value = item.template_data

    $('#not-bind-template-info').hide()
    $('#template-items-table').show()
  }

  _open (isNew = false) {
    this.isNewTemplate = isNew
    super._open()
    this.forceCloseTemplate = false
    this.load(false, isNew)
    this.container.get('editor').run()

    $(this.form).remote_form({
      model: 'template_record',
      saveButton: this.saveButton,
      onSuccess: (data) => {
        $(this.modalWindow).megamodal('hide')
        if ($('#category').length > 0) {
          $(this.categoryTreeContainer).categoriesx('forceReload')
        }
        if (this.currentTemplateContainer) {
          this._loadCurrentTemplate(data)
        }
        $(this.saveButton).loadSpin('stop')
      },
    })

    $(this.form).remote_form('clear')
    if (
      $('#category').length > 0 &&
      this.appId === 'template_record_constuctor_mode'
    ) {
      this.categoryIdInput.value = $('#category').categoriesx('getCategory').id
    } else this.categoryIdInput.value = 0

    $(this.form).attr('action', Routes.template_records_path())
    $(this.form).attr('method', 'POST')
    this.container.get('editor').loadHtml(false)
    this.container.get('editor').loadData(false)
    this.modalFooter.css('float', 'left')
  }

  _openItem () {
    if (!this.currentItem) {
      bootbox.alert(t('template_not_set'))

      return false
    }

    this.start(this.currentItem)
  }

  _save () {
    if (PrintAreaManager.hasIllegalAreas()) {
      Notificator.info(t('wrong_print_area_position'))
    } else {
      const $form = $(this.form)

      this.entityManager.clearStore()
      this.htmlInput.value = this.editorMountPoint.innerHTML
      this.dataInput.value = JSON.stringify(this._store.getState())

      if (window.gon.page.controller !== 'template_records') {
        /**
         *    в легаси бывают коллизии category_id NestedCategory,
         *    например в справочнике товары и услуги категория услуги затрёт категорию шаблона
         *    удалять нельзя, т.к. можно создавать новые шаблоны
         */
        if ($form.find('#template_record_id').val()) {
          $form.find('#template_record_category_id').attr('name', 'stubbed_template_record[category_id]')
        }
      }

      $form.submit()

      if (window.gon.page.controller !== 'template_records') {
        /**
         * Восстановление name category_id
         */
        $form.find('#template_record_category_id').attr('name', 'template_record[category_id]')
      }

      this.forceCloseTemplate = true
    }
  }

  load (item, isNew, isFromSearch) {
    if (isNew) $(this.modalBody).loadSpin('stop')
    if (!item) {
      return
    }

    this.itemTemplate = item
    $(this.form).remote_form('update', item)
    $(this.form).attr('action', Routes.template_record_path(item.id))
    $(this.form).attr('method', 'PATCH')
    if ($('#category').length > 0 && !isFromSearch) {
      this.categoryIdInput.value = $('#category').categoriesx('getCategory').id
    }

    const gS = gon.specific
    const printingToolsValues = getPrintingToolsValues({
      ...getPrintingToolsFromConfiguration(gS),
      ...getPrintingToolsFromItem(item),
    })

    const printEntryInfo = document.querySelector('#print_entry_info_wrapper input[type="hidden"]')
    printEntryInfo.value = Number(printingToolsValues.entryInfo)

    const printEntryTitle = document.querySelector('#print_entry_title_wrapper input[type="hidden"]')
    printEntryTitle.value = Number(printingToolsValues.entryTitle)

    const printClinicHeader = document.querySelector('#print_clinic_header_wrapper input[type="hidden"]')
    printClinicHeader.value = Number(printingToolsValues.clinicHeader)

    const printDoctorSignature = document.querySelector('#print_doctor_signature_wrapper input[type="hidden"]')
    printDoctorSignature.value = Number(printingToolsValues.doctorSignature)

    this.editor.loadData(item.template_data)
    this.editor.loadHtml(item.template_html)
    this.editor.repairVariables()
    $(this.modalBody).loadSpin('stop')

    this.container.get('editor').run(printingToolsValues)
  }

  copy (item) {
    if (!Utils.isPromise()) {
      item = Promise.resolve(item)
    }

    this._open(true)
    item.then((data) => {
      $(this.form).remote_form('update', data)
      this.container.get('editor').loadData(data.template_data)
      this.container.get('editor').loadHtml(data.template_html)
      this.container.get('editor').repairVariables()
    })
  }

  start (item, isFromSearch) {
    super.start(item, isFromSearch)

    const kindEl = document.querySelector('#template_record_semd_type')
    if (!kindEl) { return }
    kindEl.value = item.semd_template_document_detail?.semd_type || ''
  }
}

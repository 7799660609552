export default class BaseTree {
  constructor (container) {
    this.container = container
    this._postConstruct()
  }

  get treeContainer () {
    return this.mountPoint.find('.fancytree-container')
  }

  get tree () {
    return this.mountPoint.fancytree('getTree')
  }

  expandAll () {
    this.tree.visit((node) => node.setExpanded())
  }

  collaplseAll () {
    this.tree.visit((node) => node.setExpanded(false))
  }

  collapseAllNotRoot () {
    this.tree.visit((node) => {
      if (node.data.parent_id) node.setExpanded(false)
    })
  }

  get selectedNodes () {
    return this.tree.getSelectedNodes()
  }

  _setScroll (type = false) {
    if (!type) {
      type = 'scroll'
    }
    this.treeContainer.css('overflowY', type)
    this.treeContainer.css('minHeight', '50px')
    this.treeContainer.css('maxHeight', '600px')
    this.treeContainer.css('overflowX', 'auto')
  }

  //все константы в этой функции - это размеры элементов, которые нельзя получить при помощи функции css()
  _setPosition () {
    let elemCount = Math.min(this.nodes, 21)
    let elemHeight = 28.4
    let headHeight = 59
    let documentHeight = document.body.offsetHeight
    let documentWidth = document.body.offsetWidth
    let Yposition = parseInt($('.contexted').css('top'))
    let Xposition = parseInt($('.contexted').css('left'))
    let menuHeight = elemCount * elemHeight + headHeight
    let menuWidth = parseInt($('.contexted').css('min-width'))
    let newYposition = documentHeight - Yposition - menuHeight > 0 ? Yposition : Yposition - menuHeight
    let newXposition = documentWidth - Xposition - menuWidth > 0 ? Xposition : Xposition - menuWidth

    if (newYposition < 0) newYposition = 0

    $('.contexted').css('top', newYposition)
    $('.contexted').css('left', newXposition)
  }

  /**
   * private interface
   */
  _setConnectors () {
    this.treeContainer.addClass('fancytree-connectors')
  }

  _setActive (node) {
    this.treeContainer.find('.tree-icon.active').remove()
    $(node.span).append(`<i class="tree-icon active fad fa-arrow-alt-square-left"></i>`)
  }

  /**
   * inherit
   */
  selectNode (event, data) {
    let node = data.node
    if (data.node.folder) {
      data.node.findAll((node) => true).forEach((node) => {
        node.selected = data.node.selected
        node.render()
      })
    }
    if (node.selected) {
      node.setExpanded()
    } else {
      this._unSelectRecursive(node.parent)
    }
  }

  /**
   * inherit
   */
  _unSelectRecursive (node) {
    if (node.isRoot()) return
    node.selected = false
    node.render()
    this._unSelectRecursive(node.parent)
  }

  /**
   * abstract interface
   */
  _postConstruct () {}

  _setMountPoint () {}
}

import reducersStorage from './reducers'
import { TemplateRecordBuilder } from './builders/backend_templae_record_builder'
import { DocumentTypeBuilder } from './builders/backend_document_type_builder'
import { BackendEntryTypesBuilder } from './builders/backend_entry_types_builder'
import { FrontEntryBuilder } from './builders/frontentd_entry_builder'
import { FrontTestBuilder } from './builders/frontend_test_builder'
import { EditModeBuilder } from './builders/edit_mode_builder'

import { TemplateApplication } from './application_builder'
import { createStore } from 'redux'

let frontendBuilder = null
let applicationCached = null

const getBuilder = (type, store, params) => {
  let builder = false
  switch (type) {
    case 'backend':
      builder = new BackendEntryTypesBuilder(store)
      break
    case 'frontend':
      if (frontendBuilder) { return frontendBuilder }
      builder = new FrontEntryBuilder(store)
      frontendBuilder = builder
      break
    case 'templateRec' :
      builder = new TemplateRecordBuilder(store)
      break
    case 'documentRec' :
      builder = new DocumentTypeBuilder(store)
      break
    case 'test' :
      builder = new FrontTestBuilder(store)
      break
    case 'complexMember':
      builder = new EditModeBuilder(store, params)
      break
  }

  return builder
}

const getApplication = (type, mode, params) => {
  if (type === 'frontend' && applicationCached) { return applicationCached }
  const store = createStore(
    reducersStorage,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

  const builder = getBuilder(type, store, params)
  const templateApplicationManager = new TemplateApplication()
  const application = templateApplicationManager.build(builder)
  application.destroy = builder.freeComponents

  applicationCached = application

  application.run()

  return application
}

const applicationFactory = (type, mode, params = false) => {
  window.application.form_builder = {}

  if (!window.application.form_builder[type] || type === 'complexMember') {
    console.debug('new template application')

    const application = getApplication(type, mode, params)

    application.globalMap = {
      lastTarget: false,
      lastRange: false,
    }

    application.errors = []

    window.application.form_builder[type] = application
  }

  if (mode) {
    window.application
      .form_builder[type]
      ._components.modal.mode = mode
  }

  window
    .application
    .form_builder.current = window.application.form_builder[type]

  if (
    type === 'templates' ||
    type === 'test' ||
    type === 'complexMember'
  ) {
    window.application.form_builder[type]._components.modal._open()
  }
}

export default {
  generate: applicationFactory,
}

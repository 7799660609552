import Container from 'container'
import UserException from '../components/exeptions'
export default class baseTemplateBuilder {
  constructor (store) {
    this._components = {}
    this.store = store
    this.container = new Container()
  }

  /**
   * @public
   * @param name
   * @param component
   */
  addComponent (name, component) {
    this._components[name] = component
  }

  removeComponent (name) {
    this._components[name] = null
    delete this._components[name]
  }

  /**
   * Run application
   * @public
   */
  run () {
    for (const component in this._components) {
      this._components[component].init(this.store, this._components)
    }
  }

  /**
   * @public
   * @abstract
   */
  initComponents () {
    throw new UserException('initComponents is abstract , please define method')
  }

  /**
   * @public
   * @abstract
   */
  create () {
    throw new UserException('create is abstract , please define method')
  }

  /**
   *
   */
  setMapping () {}
}

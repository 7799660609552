import baseTemplateComponent from '../base_component'

export default class VariableEditor extends baseTemplateComponent {
  constructor (params, builder) {
    super(params, builder)
    this._buildModal()
    this._bindEvents()
  }

  _buildModal () {
    this._window = $('#form-builder-variable-editor-modal')
    this._windowBody = this._window.find('.modal-body')
    this._defautlValueInput = $('#variable-form_value')
    this._window.megamodal({btnResize: false})
  }

  _bindEvents () {
    this._window.find('.modal-save').on('click', this.save.bind(this))
    this._window.find('form').on('submit', (e) => e.preventDefault())
  }

  show (entity) {
    this.entity = entity
    this._window.megamodal('show')
    this.entityID = this.entity.id
    this._defautlValueInput
      .val('')
      .val(entity.data.value)
  }

  /**
   * return :void
   * @params e - event
   */
  save (event) {
    event.preventDefault()
    let value = this._defautlValueInput.val()
    this._window.megamodal('hide')

    this.store.dispatch({
      type: this.entity.actions.UPDATE,
      payload: {
        id: this.entity.id,
        value,
      },
    })
  }
}

export default class EventManager {
  constructor () {
    this.instance = this
  }

  pullEvents (context) {
    context['activateNode'] = this.activateNode
    context['selectNode'] = this.selectNode
    context['icon'] = this.icon
  }

  icon (event, data) {
    if (data.node.key === '_1') {
      return 'fad fa-tree'
    }
    if (!data.node.folder) {
      return 'fad fa-file'
    }
  }

  selectNode (event, data) {
    let node = data.node
    if (data.node.folder) {
      data.node.findAll((node) => true).forEach((node) => {
        node.selected = data.node.selected
        node.render()
      })
    }
    if (node.selected) {
      node.setExpanded()
    } else {
      EventManager._unSelectRecursive(node.parent)
    }
  }

  activateNode (event, data) {}

  /**
   * private methods
   */
  static _unSelectRecursive (node) {
    if (node.isRoot()) return
    node.selected = false
    node.render()
    this._unSelectRecursive(node.parent)
  }
}

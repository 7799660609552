import { el, mount } from 'redom'
import ContextMenu from './base.js'

class MenuWindow {
  constructor () {
    this.el = el('div.contexted', {
      contenteditable: false,
      style: this.style,
    })
  }

  get style () {
    return {
      position: 'absolute',
      zIndex: 100000,
    }
  }
}

export default class CModeContextMenu extends ContextMenu {
  create (e) {
    this.window = (new MenuWindow()).el
    this._setMenuPositon(e)
    this._attactHeader()
    this._attactBody()
    this._bindDragAndDrop(this.window)

    return true
  }

  _attactHeader () {
    const menuHead = document.createElement('div')
    const header = el('div.contexted-head.contructor-mode', {
      id: 'contexted-head',
    })

    const closeBnt = el('i.fad.fa-times.contexted-close', {
      onclick: (event) => this.forceDelete(),
    })

    mount(menuHead, header)
    mount(menuHead, closeBnt)
    mount(this.window, menuHead)
  }

  _attactBody () {
    const body = el('ul.contexted_ul',
      el('li.contexted_li', t('delete'), {
        onclick: () => {
          this.container.get('editor').deleteNode(this.target)
          // this.store.dispatch({
          //   type: actions.DELETE,
          //   payload: this.entity.id
          // })
        },
      })
    )
    mount(this.window, body)
  }
}

<template>
  <popover
    class="textarea-proxy"
    :class="{ 'use-backend-z-index': isFrontendTest }"
    width="600"
    yes-style="warning"
    :cancel-text="t('cancel')"
    :confirm-text="t('save')"
    inherited-visibility
    visibility
    @yes="$emit('save')"
    @no="$emit('close')"
  >
    <template #footer-left>
      <el-checkbox
        v-model="verticalMode"
        class="textarea-proxy__vertical-mode el-checkbox_big"
        :label="t('vertical_mode')"
      />
    </template>

    <template #message>
      <span class="bold">{{ name || t('variable_edit') }}</span>
      <textarea
        id="textarea_proxy_textarea"
        ref="textareaRef"
        v-model="content"
        class="textarea-proxy__textarea"
        @click="updateCaretPos"
        @keydown="updateCaretPos"
        @change="updateCaretPos"
      />

      <el-tree
        class="textarea-proxy__tree"
        :data="treeData"
        :props="treeDefaultProps"
        :default-expanded-keys="defaultExpandedKeys"
        :render-content="treeNodeRenderFunction"
        node-key="id"
        @node-click="update"
      />
    </template>
  </popover>
</template>

<script>
import Popover from '@/vue_components/common/popover/popover'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import {
  isContentEqualsToWindowSelection,
  treeNodeRenderFunction,
  updateContent,
} from '@/plugins/dynamic_forms/components/context_menu/editor_mode/ui/TextareaProxyRoot/logic'

export default {
  name: 'TextareaProxy',
  components: { Popover },

  props: {
    target: {
      type: HTMLElement,
      required: true,
    },

    contentValue: PropsTypes.String(),
    treeDefaultProps: PropsTypes.Object({
      children: 'children',
      label: 'title',
    }),

    treeData: PropsTypes.Array(),
    name: PropsTypes.String(),
  },

  data () {
    return {
      caretPos: 0,
      verticalMode: false,

      treeNodeRenderFunction,
    }
  },

  computed: {
    defaultExpandedKeys () {
      return this.treeData?.length
        ? [this.treeData[0].id]
        : []
    },

    content: {
      get () { return this.contentValue },
      set (value) { this.$updateSync('contentValue', value) },
    },

    spaceSeparator () {
      return this.verticalMode ? '\n' : ' '
    },

    isFrontendTest () {
      return gon.page.controller === 'template_records'
    },
  },

  created () {
    this.caretPos = this.content.length
    if (this.isFrontendTest) { this.subscribeToFrontendTestEvents() }
  },

  mounted () {
    const { lastRange } = window.application.form_builder?.frontend?.globalMap
    this.setDefaultSelectedRange(lastRange)
  },

  methods: {
    setDefaultSelectedRange ({ start, end }) {
      const hasSelection = start && end // getRangeAt() или что-то подобное вернёт весь тег без start и end
      const hasContent = this.content.trim()

      // выделено всё
      if (!hasSelection && hasContent && isContentEqualsToWindowSelection(this.content)) {
        return this.$refs.textareaRef.select()
      }

      // выделена часть
      this.$refs.textareaRef.focus()
      this.$nextTick(() => {
        this.$refs.textareaRef.setSelectionRange(start, end)
      })
    },

    updateCaretPos ($event) {
      this.caretPos = $event.target.selectionStart ?? this.content.length
    },

    update ({ title: value, folder }) {
      if (folder) { return }

      const { selectionStart, selectionEnd } = this.$refs.textareaRef

      this.content = updateContent(
        this.content,
        value,
        this.caretPos,
        this.spaceSeparator,
        { selectionStart, selectionEnd }
      )

      this.caretPos = selectionStart !== selectionEnd
        ? selectionStart + value.length // +1 нет, т.к. дополнительный символ уже заложен
        : this.caretPos + value.length + 1
    },

    /**
     * Костыль для реакции на закрытие тест режима шаблона протокола
     */
    subscribeToFrontendTestEvents () {
      const frontendTest = document.querySelector('#frontend-form')
      if (!frontendTest) { return }

      const buttonsClose = frontendTest.querySelectorAll('.modal-close')
      if (!buttonsClose.length) { return }

      const that = this
      const emitter = () => {
        that.$emit('close', 'Закрыто костылём subscribeToFrontendTestEvents')
      }

      buttonsClose.forEach((button) => {
        button.addEventListener('click', () => {
          emitter(button)
          button.removeEventListener('click', emitter)
        })
      })
    },
  },

}
</script>

// Может быть использован когда не подходит обработка полей через NestedFieldSet
// в том числе, когда перехватывается управление фронтом через остановку событий формы.

const _errorFrameClass = 'has-error has-feedback'
const _underFieldErrorClass = 'under_field_error'

export default {
  showErrorMessages (field, errors) {
    if (!Array.isArray(errors)) return
    this.hideErrorMessages(field)

    let errMessages = errors.join(', ')

    $(field)
      .addClass(_errorFrameClass)
      .append(`<div class = ${_underFieldErrorClass}>${errMessages}</div>`)
  },

  hideErrorMessages (field) {
    $(field)
      .removeClass(_errorFrameClass)
      .find(`.${_underFieldErrorClass}`)
      .remove()
  },
}

<template>
  <m-date-picker
    class="w-default-input-width"
    :value="semdEntity.value"
    :label="semdEntity.title"
    :m-fixed-height="false"
    value-format="yyyy-MM-dd"
    @change="onChange"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import { SemdDatePicker } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdDatePicker/SemdDatePicker'

export default defineComponent({
  name: 'SemdDatePickerView',
  components: { MDatePicker },
  props: {
    semdEntity: {
      type: Object as PropType<SemdDatePicker>,
      required: true,
    },
  },

  emits: [
    'change',
  ],

  methods: {
    onChange (value) {
      this.$emit('change', {
        semdEntity: this.semdEntity,
        newValue: value,
      })
    },
  },
})
</script>

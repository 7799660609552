import { SemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdEntity'

export class SemdTimePicker extends SemdEntity {
  value: string

  constructor (props) {
    super(props)
    this.value = props.value
  }

  drawElementValue (value: string) {
    this.element.innerHTML = value || ''
    this.isValid()
  }

  getContent (): string {
    return super.getContent() as string
  }
}

export default class PrintAreaModal {
  constructor (container) {
    this.modal = ModalFactory({
      title: t('print_areas'),
      showButtons: false,
      fullscreenButton: false,
    })
    this.container = container
    this.body = this.modal[0].querySelector('.modal-body')
    this.modal[0].id = 'print_area_modal'
    this.hTemplate = Handlebars.compile(this._html())
    this.printAreaManager = container.get('print_area_manager')
  }

  render () {
    this.body.innerHTML = this.hTemplate(this.printAreaManager.items)
    this._bindEvents()
  }

  getItem (id) {
    return this.printAreaManager.items.find((item) => item.id == id)
  }

  _bindEvents () {
    const trItems = this.body.querySelectorAll('.print_area_selector_item')
    const addNewBtn = this.body.querySelector('.add_new')
    addNewBtn.onclick = (event) => this._createNew()

    trItems.forEach((elem) => {
      const item = this.getItem(elem.dataset.id)
      const edit = elem.querySelector('.edit_btn')
      const remove = elem.querySelector('.remove_btn')
      const title = elem.querySelector('.title')

      if (item.editing) {
        const accept = elem.querySelector('.accept_title')
        const stop = elem.querySelector('.stop_editing')
        const input = elem.querySelector('.item_title_edit')
        input.focus()
        input.setSelectionRange(0, input.value.length)
        input.onkeypress = (event) => {
          if (event.keyCode == 13) this._stopEdit(item, input.value)
        }
        accept.onmousedown = (event) => this._stopEdit(item, input.value)
        stop.onmousedown = (event) => this._stopEdit(item)
        input.onblur = (event) => this._stopEdit(item)
      } else {
        title.ondblclick = (event) => this._startEdit(item)
      }

      edit.onclick = (event) => this._startEdit(item)
      remove.onclick = (event) => this._removeItem(item)
    })
  }

  _html () {
    return `<table class="print_area_selector_table table-striped">
              <tbody>
                {{#each this}}
                  <tr class="print_area_selector_item" data-id="{{this.id}}"">
                    <td class="item_title_container">
                      {{#if this.editing}}
                        <input class="item_title_edit" value="{{this.title}}">
                        <i class="fad fa-check accept_title fa-border"></i>
                        <i class="fad fa-times stop_editing fa-border"></i>
                      {{else}}
                        <span class="title">{{this.title}}</span>  
                      {{/if}}
                    </td>
                    <td class="item_edit_btn_container">
                      <i class="fad fa-edit edit_btn fa-border"></i>
                      <i class="fad fa-trash-alt remove_btn fa-border"></i>
                    </td>
                  </tr>
                {{/each}}
              </tbody>
            </table>
            <i class="fad fa-plus add_new fa-border"></i>`
  }

  _startEdit (item) {
    if (item.editing) return
    this.printAreaManager.items.forEach((item) => {
      if (item.editing) {
        item.editing = false
        this.printAreaManager.update(item)
      }
    })
    item.editing = true
    this.printAreaManager.update(item)
    this.render()
  }

  _stopEdit (item, title) {
    if (!item.editing) return
    item.editing = false
    if (title) item.title = title
    this.printAreaManager.update(item)
    this.render()
  }

  _removeItem (item) {
    this.printAreaManager.destroy(item)
    this.render()
  }

  _createNew () {
    const id = this.printAreaManager.nextAvailableID()
    const newItem = {
      id,
      title: `${t('print_area')} (${id})`,
      editing: false,
    }
    this.printAreaManager.create(newItem)
    this._startEdit(newItem)
  }

  show () {
    this.modal.megamodal('show')
  }

  hide () {
    this.modal.megamodal('hide')
  }
}

import Modal from './base'

export default class FrontModalTest extends Modal {
  load () {
    this.editor.loadData(localStorage.formData)
    this.editor.loadHtml(localStorage.formHtml)
    this.editor.repairVariables()
    $(this.modalBody).loadSpin('stop')
  }

  preparePrintPanel () {
    let date = new Date()
    $('#print-panel-title').html($('#template_record_title').val())
    $('#print-panel__date').html(
      date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear(),
    )
  }

  _open () {
    super._open()
    $(this.modalWindow).css('z-index', 10000)
    $(this.modalWindow).find('.modal-save').hide()
    this.resizeBodyContainer()
    this.editor.run()
  }
}

import { SemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdEntity'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class SemdDatePicker extends SemdEntity {
  value: string

  constructor (props) {
    super(props)
    this.value = props.value
  }

  drawElementValue (value: string) {
    this.element.innerHTML = vueFilters.date(value) || ''
    this.isValid()
  }

  getContent (): string {
    return super.getContent() as string
  }
}

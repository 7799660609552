
export const FAKE_NODE_ID1 = 2000000001
export const FAKE_NODE_ID2 = 2000000002

export const DISCOUNT_TYPES = Object.freeze({
  PERCENT: 'percent',
  CURRENCY: 'currency',
})

export const SYSTEM_DISCOUNTS = Object.freeze({
  MANUAL: 'manual',
  PERSONAL: 'personal_discount',
  ACCUMULATIVE: 'accumulative_discount',
  ACCOUNTED: 'accumulative_discount',
  BONUS: 'bonus_points',
})

export const MANUAL_DISCOUNT_ID = 4

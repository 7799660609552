import baseTemplateComponent from '../base_component'

export default class CategoriesWrap extends baseTemplateComponent {
  run () {
    const TRGroupForm = FormFactory.build(
      'template_record_members_editing', {
        list: this.category,
      }
    )

    const actions = {
      move: t('move'),
      delete: t('delete'),
    }

    // Демомед доступен для всех, поэтому любой может написать в протоколах всё что угодно
    // и за это нам вероятно могут что-то предъявить, поэтому экспорт забанен
    if (gon.specific.rails_env !== 'demo') {
      actions.export = t('export')
    }

    this.category.categoriesx({
      url: '/categories/template_records',
      category_type: 8,
      editable: true,
      groupEdit: true,
      groupForm: TRGroupForm,
      itemUrl: Routes.template_record_path,
      itemForm: FormFactory.core({
        form: $('#template-record-form'),
        model: 'template_record',
        fnItemsPath: Routes.template_records_path,
        fnItemPath: Routes.template_record_path,
      }),
      responsive: {
        offset: 128,
      },
      actions,
      onItemEdit: (item) => {
        this._components.modal.start(item)
      },
      onItemRemove: (item) => {
        Utils.askDestroy({
          title: item.title,
          url: Routes.template_record_path(item.id) + '.json',
          success: () => {
            this.category.categoriesx('removeItem', item)
          },
        })
      },
      onItemMove () { },
      onItemCopy: (item) => {
        this._components.modal.copy(item.then((loadedItem) => _.clone(loadedItem)))
      },
      onClick: (item) => {
        this._components.modal.start(item)
      },
    })

    Utils.resizeToFullscreen()

    this.addCategoryButton.on('click', () => {
      this.category.categoriesx('newCategory')
    })

    this.search.smartSearch({
      url: '/template_records/search',
      method: 'get',
      onSelect: (item) => {
        this._components.modal.start(item, true)
        setTimeout(() => {
          this.search.val('').change(item)
        }, 300)
      },
      itemRender (html, item) {
        const tmpRecord = $('<span>').addClass('cut')
          .text(item.title)

        return html.prepend(tmpRecord)
      },
    })
  }
}

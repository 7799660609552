import { CALCULATE_ACTIONS } from '../actions'

class UpdateFormulasListener {
  /**
   * insert data to tinymce to fix element
   */
  handle (event) {
    let oldData = event.oldData
    let manager = event.manager
    let store = manager.store
    let findMathRegExp = new RegExp(`${oldData.data.name}`)
    let calc = store.getState().calculate.find(
      (item) => item.value.match(findMathRegExp),
    )
    if (calc) {
      let newValue = calc.value.replace(findMathRegExp, event.newData.name)
      store.dispatch({
        type: CALCULATE_ACTIONS.UPDATE,
        payload: { id: calc.id, value: newValue },
      })
    }
  }
}

export default UpdateFormulasListener

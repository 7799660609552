import baseTemplateComponent from '../base_component'

export default class CategoriesWrap extends baseTemplateComponent {
  run () {
    const TRGroupForm = FormFactory.build(
      'document_type_members_editing', {
        list: this.category
      }
    )

    this.category.categoriesx({
      url: '/categories/document_types',
      category_type: 14,
      editable: true,
      groupEdit: true,
      groupForm: TRGroupForm,
      itemUrl: Routes.document_type_path,
      itemForm: FormFactory.core({
        form: $('#document_type_form'),
        model: 'document_type',
        fnItemsPath: Routes.document_types_path,
        fnItemPath: Routes.document_type_path
      }),
      responsive: {
        offset: 128
      },
      actions: {
        'move': t('move'),
        'delete': t('delete'),
        'export': t('export')
      },

      onItemEdit: async (item) => {
        this._components.modal.start(await item)
      },
      onItemRemove: item => {
        Utils.askDestroy({
          title: item.title,
          url: Routes.document_type_path(item.id) + '.json',
          success: () => {
            this.category.categoriesx('removeItem', item)
          }
        })
      },
      onItemMove: function () { },
      onItemCopy: (item) => {
        this._components.modal.copy(item.then((loadedItem) => _.clone(loadedItem)))
      },
      onClick: (item) => {
        this._components.modal.start(item)
      },
    })

    Utils.resizeToFullscreen()

    this.addCategoryButton.on('click', () => {
      this.category.categoriesx('newCategory')
    })

    this.search.smartSearch({
      url: '/document_types/search',
      method: 'get',
      onSelect: item => {
        this._components.modal.start(item)
        setTimeout(() => {
          this.search.val('').change(item)
        }, 300)
      },
      itemRender: function (html, item) {
        var docType = $('<span>').addClass('cut')
          .text(item.title)

        return html.prepend(docType)
      }
    })
  }
}

<template>
  <validation-wrapper
    :m-fixed-height="mFixedHeight"
    :errors="validationErrors"
    class="m-time-picker"
  >
    <el-time-picker
      v-mask="mask"
      :value="startTime"
      :value-format="valueFormat"
      :format="pickerOptions.format"
      @input="$emit('changeStartTime', $event)"
    />

    <el-time-picker
      v-if="timeRange"
      :value="endTime"
      :value-format="valueFormat"
      :format="pickerOptions.format"
      @input="$emit('changeEndTime', $event)"
    />
    <m-label
      v-if="label"
      :label="label"
      :required="Boolean(required)"
      class="time-picker-label"
    />
  </validation-wrapper>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import MLabel from '@/vue_present/_base/inputs/MLabel.vue'

type TPickerOptions = {
  format: string
  selectableRange: string
}

const DEFAULT_FORMAT = 'HH:mm'

export default defineComponent({
  name: 'MTimePicker',
  components: { MLabel, ValidationWrapper },
  mixins: [
    ValidationChildMixin, DisabledMixin,
  ],

  props: {
    timeRange: Boolean,
    label: { type: String, default: null },
    startTime: { type: String, default: window.Utils.getFormattedTime() },
    endTime: { type: String, default: null },

    pickerOptions: {
      type: Object as PropType<TPickerOptions>,
      default: () => ({
        format: DEFAULT_FORMAT,
        selectableRange: '00:00 - 23:59',
      }),
    },

    valueFormat: { type: String, default: DEFAULT_FORMAT },
  },

  emits: [
    'changeStartTime',
    'changeEndTime',
  ],

  computed: {
    mask () {
      return this.pickerOptions.format.replace(/\w/g, '#')
    },
  },
})
</script>

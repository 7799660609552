import { getMap } from '../configuration/mapping'
import FrontModal from '../components/modal/front_entries_modal'
import FrontendEditor from '../components/editor/frontend_editor'
import ContextMenu from '../components/context_menu/editor_mode'
import baseTemplateBuilder from './base_builder'

import Api from '../api'
import AssignEntitiesTree from '../tree/assigned_entities_tree'
import { registerListeners, unregisterListeners } from '../listeners'
import Dispatcher from 'dispatcher'
import EntityManager from '../entities'
import SelectMkbTree from '../tree/select_mkb_tree'
import SelectMkbModal from '../components/modal/select_mkb_modal'
import MkbModal from '../components/modal/mkb_modal'
import DiseasesTree from '../tree/diseases_tree'
import EntityFactory from '../entities/factory'
import { mainCofig } from '../configuration/main_config'
import PrintAreaManager from '../entities/print_area_manager'

export class FrontEntryBuilder extends baseTemplateBuilder {
  setMapping () {
    const customMap = {
      appId: 'entry_edit_mode',
      openButton: document.querySelector('#protokol'),
      dataInput: document.querySelector('input#template_data'),
      htmlInput: document.querySelector('input#template_html'),
      presetHtmlContainer: document.querySelector('#protokol-content'),
      changeProtocolButton: document.querySelector('#change_template_protokol'),
      basedOnPreviousBtn: document.querySelector('#based_on_previous_modal_btn'),
      protocolSelectModal: document.querySelector('#template_record-list-modal'),
      protocolSaveInput: document.querySelector('#entry_is_protokol_save'),
    }

    const baseMap = getMap('frontend')
    baseMap.editorSettings
      .toolbar1 = baseMap.editorSettings.toolbar1.replace('print', '')
    this.map = Object.assign(baseMap, customMap)
  }

  initComponents () {
    this.container.add('store', this.store)
    registerListeners(this.container)
    this.container.add('dispatcher', new Dispatcher(this.container))
    const entityManager = new EntityManager(this.container)
    this.container.add('entity_manager', entityManager)
    this.container.add('api', new Api(this.container))
    this.container.add(
      'assigned_items_tree',
      new AssignEntitiesTree(this.container))
    const cMenu = new ContextMenu(this.map, this)
    this.container.add('config', mainCofig)
    this.container.add('contextMenu', cMenu)

    this.container.add('select_mkb_modal', new SelectMkbModal(this.container))
    this.container.add('select_mkb_tree', new SelectMkbTree(this.container))
    this.container.add('entity_factory', new EntityFactory(this.map, this))
    this.container.add('editor', new FrontendEditor(this.map, this))
    this.container.add('print_area_manager', new PrintAreaManager(this.container))
    this.container.add('mkb_modal', new MkbModal(this.container))
    this.container.add('diseases_tree', new DiseasesTree(this.container))

    this.addComponent('modal', new FrontModal(this.map, this))
    const selectMkbModal = this.container.get('select_mkb_modal')
    selectMkbModal.saveButton.on('click', selectMkbModal.save.bind(selectMkbModal))

    const mkbModal = this.container.get('mkb_modal')
    mkbModal.saveButton.on('click', mkbModal.save.bind(mkbModal))
  }

  freeComponents () {
    const mkbModal = this.container.get('mkb_modal')
    mkbModal.saveButton.onclick = null

    const selectMkbModal = this.container.get('select_mkb_modal')
    selectMkbModal.saveButton.onclick = null

    this.removeComponent('modal')
    this.removeComponent('mkb_modal')
    this.removeComponent('print_area_manager')
    this.removeComponent('editor')
    this.removeComponent('entity_factory')
    this.removeComponent('select_mkb_tree')
    this.removeComponent('select_mkb_modal')
    this.removeComponent('contextMenu')
    this.removeComponent('config')
    this.removeComponent('assigned_items_tree')
    this.removeComponent('api')
    this.removeComponent('entity_manager')
    this.removeComponent('dispatcher')

    unregisterListeners(this.container)

    this.removeComponent('store')
  }

  create () {
    return this
  }
}

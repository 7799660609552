import Listener from './listener'
class ShowPanelListener extends Listener {
  /**
   * insert data to tinymce to dynamic element
   */
  handle (event) {
    let manager = this.container.get('entity_manager')
    let entity = manager.getEntity(event.element)
    if (!entity) {
      return
    }
    entity.edit()
  }
}

export default ShowPanelListener

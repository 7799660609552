<template>
  <m-select
    :value="selectedSemdEntities"
    :items="semdEntities"
    collapse-tags
    multiple
    :m-fixed-height="false"
    :placeholder="placeholder"
    @removeTag="$emit('removeTag', $event)"
    @handleOptionClick="$emit('handleOptionClick', $event)"
    @clear="$emit('clear', deleteLayoutConfirmationRequired)"
  >
    <template #option="{ option }">
      <semd-entity-option-label :semd-entity="option" />
    </template>
  </m-select>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import SemdEntityOptionLabel
  from '@/vue_apps/Protocols/SemdProtocolEditor/components/SemdEntitySelectWrapper/SemdEntityOptionLabel.vue'
import { ISemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/ISemdEntity'
import { SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'

export default defineComponent({
  name: 'SemdEntitySelect',
  components: {
    SemdEntityOptionLabel,
    MSelect,
  },

  props: {
    semdType: {
      type: String as PropType<keyof typeof SEMD_TYPE_ENUM>,
      required: true,
    },

    selectedSemdEntities: {
      type: Array as PropType<ISemdEntity[]>,
      required: true,
    },

    semdEntities: {
      type: Array as PropType<ISemdEntity[]>,
      required: true,
    },
  },

  emits: [
    'removeTag',
    'handleOptionClick',
    'clear',
  ],

  computed: {
    placeholder () {
      return window.t(this.semdType, { scope: 'semds.semdType' })
    },

    deleteLayoutConfirmationRequired () {
      // не обращай внимание на false, важно название 'deleteLayoutConfirmationRequired'
      return false
    },
  },
})
</script>

<template>
  <context-popup
    v-if="activeSemdEntity"
    ref="contextPopup"
    class="position-fixed"
    style="z-index: 9999"
    :use-auto-close="false"
  >
    <div class="flex gap-indent-small">
      <component
        :is="activeSemdEntityComponent"
        :semd-entity="activeSemdEntity"
        @change="$emit('editActiveSemdEntity', $event)"
      />

      <m-button
        template="close"
        @click="setActiveSemdEntity(null)"
      />
    </div>
  </context-popup>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ISemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/ISemdEntity'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import ContextPopup from '@/vue_present/Reuse/ContextPopup/ContextPopup.vue'
import { FRONTEND_MOUNT_POINT } from '@/plugins/dynamic_forms/custom_plugins/event_manager_extension'
import { TTinyMCEEditor } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/TTinyMCE'
import {
  SemdNsiDictionary,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdNsiDictionary/SemdNsiDictionary'
import { SemdDatePicker } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdDatePicker/SemdDatePicker'
import {
  SemdNsiMeasurement,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdNsiMeasurement/SemdNsiMeasurement'
import { SemdTimePicker } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdTimePicker/SemdTimePicker'
import SemdTimePickerView
  from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdTimePicker/SemdTimePickerView.vue'
import SemdNsiDictionaryView
  from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdNsiDictionary/SemdNsiDictionaryView.vue'
import SemdDatePickerView
  from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdDatePicker/SemdDatePickerView.vue'
import SemdNsiMeasurementView
  from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdNsiMeasurement/SemdNsiMeasurementView.vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'SemdEntityContextPopup',
  components: {
    ContextPopup,
    MButton,

    SemdNsiDictionaryView,
    SemdDatePickerView,
    SemdNsiMeasurementView,
    SemdTimePickerView,
  },

  model: {
    prop: 'activeSemdEntity',
    event: 'change',
  },

  props: {
    editor: {
      type: Object as PropType<TTinyMCEEditor>,
      required: true,
    },

    semdEntities: {
      type: Array as PropType<ISemdEntity[]>,
      required: true,
    },

    activeSemdEntity: {
      type: Object as PropType<ISemdEntity>,
      default: null,
    },

    isTemplateRecords: Boolean,
  },

  emits: [
    'change',
    'editActiveSemdEntity',
  ],

  computed: {
    ...mapGetters('windowStore', {
      vxGetZoom: 'vxGetZoom',
    }),

    activeSemdEntityComponent () {
      switch (true) {
        case this.activeSemdEntity instanceof SemdNsiDictionary: return 'SemdNsiDictionaryView'
        case this.activeSemdEntity instanceof SemdDatePicker: return 'SemdDatePickerView'
        case this.activeSemdEntity instanceof SemdNsiMeasurement: return 'SemdNsiMeasurementView'
        case this.activeSemdEntity instanceof SemdTimePicker: return 'SemdTimePickerView'
        default: return null
      }
    },

    isFrontendEditor () {
      return this.editor.id === FRONTEND_MOUNT_POINT
    },
  },

  mounted () {
    this.$pubSub.subscribe(`onSemdSelectorClick:${this.editor.id}`, this.onSemdSelectorClick)
    this.$pubSub.subscribe(`onSemdSelectorPopoverClose:${this.editor.id}`, () => this.setActiveSemdEntity(null))
  },

  beforeDestroy () {
    this.$pubSub.unsubscribe(`onSemdSelectorClick:${this.editor.id}`, this.onSemdSelectorClick)
    this.$pubSub.unsubscribe(`onSemdSelectorPopoverClose:${this.editor.id}`, () => this.setActiveSemdEntity(null))
  },

  methods: {
    setActiveSemdEntity (semdEntity: ISemdEntity) {
      this.$emit('change', semdEntity)
    },

    onSemdSelectorClick ({ context }) { /* eslint-disable-line */
      this.setActiveSemdEntity(null)
      const { target, event } = context

      /** @type {ISemdNsiDictionary} */
      const semdEntity = this.semdEntities.find(({ id }) => id === target.id)
      if (!semdEntity) { return }

      this.drawActiveItemContextMenu(event, semdEntity)
    },

    drawActiveItemContextMenu (event: PointerEvent, semdEntity: ISemdEntity) {
      this.$nextTick(() => {
        this.setActiveSemdEntity(semdEntity)

        const { clientX, clientY } = this.__extractEventPosition(event)

        this.$nextTick(() => {
          this.$refs.contextPopup.onContextMenuHandler({
            ...event,
            clientX,
            clientY,
          })
        })
      })
    },

    __extractEventPosition (event: PointerEvent): { clientY: number; clientX: number } {
      if (!this.isTemplateRecords) {
        return {
          clientX: event.x - this.editor.targetElm.offsetLeft,
          clientY: event.clientY - 280 * this.vxGetZoom,
        }
      }

      const eventTarget: HTMLElement = event.target as HTMLElement

      if (this.isFrontendEditor) {
        return {
          clientX: eventTarget.offsetLeft,
          clientY: event.offsetY + eventTarget.offsetTop - 5 * this.vxGetZoom,
        }
      }

      return {
        clientX: eventTarget.offsetLeft - 250 * this.vxGetZoom,
        clientY: event.screenY - 450 * this.vxGetZoom,
      }
    },
  },
})
</script>

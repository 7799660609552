
export const orderStartSpin = () => {
  $('#main_body')
    .spin(true)
    .css({ opacity: '0.7' })
}

export const orderStopSpin = () => {
  $('#main_body')
    .spin(false)
    .css({ opacity: '1' })
}

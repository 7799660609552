<template>
  <span class="hidden" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { SEMD_TYPE_ELEMENT_ID } from '@/vue_apps/Protocols/const/const'
import { CONFIRM } from '@/vue_present/_base/MConfirm/MConfirm'
import { SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import { ISemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/ISemdEntity'
import { ConfirmationMixin } from '@/vue_present/mixins/ConfirmationMixin'

export default defineComponent({
  name: 'SemdTemplateRecordWatcher',

  mixins: [
    ConfirmationMixin,
  ],

  props: {
    semdType: {
      type: String as PropType<keyof typeof SEMD_TYPE_ENUM>,
      default: null,
    },

    selectedSemdEntities: {
      type: Array as PropType<ISemdEntity[]>,
      required: true,
    },

    isTemplateRecords: Boolean,
  },

  emits: [
    'semdTypeChange',
  ],

  mounted () {
    const selector: HTMLSelectElement = document.querySelector(SEMD_TYPE_ELEMENT_ID)
    if (!selector) { return }
    this.setSemdType(selector.value)

    this.subscribeToSemdSelectorEvents(selector)
  },

  beforeDestroy () {
    this.unSubscribeFromSemdSelectorEvents(document.querySelector(SEMD_TYPE_ELEMENT_ID))
  },

  methods: {
    subscribeToSemdSelectorEvents (el) {
      if (!el) { return }
      el.addEventListener('change', this.onSemdTypeChange)
    },

    unSubscribeFromSemdSelectorEvents (el) {
      if (!el) { return }
      el.removeEventListener('change', this.onSemdTypeChange)
    },

    async onSemdTypeChange (event) {
      if (!this.isTemplateRecords) { return }

      const value = event.target.value

      if (
        this.semdType &&
          this.selectedSemdEntities?.length &&
          (await this.confirmDelete(window.t('semds.delete.fullLayout'))) !== CONFIRM
      ) {
        event.target.value = this.semdType || ''

        return
      }

      this.setSemdType(value)
    },

    setSemdType (semdType: keyof typeof SEMD_TYPE_ENUM = null) {
      this.$emit('semdTypeChange', semdType || null)
    },
  },
})
</script>

/**
 * @deprecated - возможно даже можно удалить
 */
class InsertDynamicEntityListener {
  /**
   * insert data to tinymce to dynamic element
   */
  handle (event) {
    console.info('@deprecated InsertDynamicEntityListener', event)
  }
}

export default InsertDynamicEntityListener

<template>
  <m-icon
    v-tooltip="validationIcon.tooltip"
    :icon="validationIcon.icon"
    :color="validationIcon.color"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { ISemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/ISemdEntity'

export default defineComponent({
  name: 'SemdEntitiesWarning',
  components: { MIcon },
  props: {
    semdEntities: {
      type: Array as PropType<ISemdEntity[]>,
      default: () => [],
    },
  },

  computed: {
    notSelectedRequiredItems () {
      return this.semdEntities
        .filter((semdEntity) => !semdEntity.isOptional && !semdEntity.element)
    },

    notSelectedRequiredItemsTitles () {
      return this.notSelectedRequiredItems.map((item) => item.title).join(', ')
    },

    validationIcon () {
      if (!this.notSelectedRequiredItemsTitles) {
        return {
          icon: 'ready',
          color: 'success',
          tooltip: null,
        }
      }

      return {
        icon: 'warning',
        color: 'warning',
        tooltip: window.t('errors.has_not_existed_fields', [this.notSelectedRequiredItemsTitles]),
      }
    },
  },
})
</script>

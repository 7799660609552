import { SemdDatePicker } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdDatePicker/SemdDatePicker'
import { SEMD224_ENTITY_NAMES } from '@/vue_apps/Protocols/SemdProtocolEditor/entities/Semd224/semd224EntityNames'
import {
  SemdListSeparator,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdListSeparator'
import {
  SemdNsiMeasurement,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdNsiMeasurement/SemdNsiMeasurement'
import { SemdTimePicker } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdTimePicker/SemdTimePicker'
import {
  SemdNsiDictionary,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/SemdNsiDictionary/SemdNsiDictionary'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'
import { SemdArea } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdArea'
import {
  getDatesErrorMessageOrValid,
  notifyWhenNotValid,
} from '@/vue_apps/Protocols/SemdProtocolEditor/entities/Semd224/semd224EntitiesValidators'

const $t = (key: string) => window.t(key, { scope: 'semds.semd224' })

const customDateValidator = (dateStart: SemdDatePicker, dateEnd: SemdDatePicker) => () => {
  const datesErrorMessageOrValid = getDatesErrorMessageOrValid(dateStart, dateEnd)
  notifyWhenNotValid(dateStart.element, dateEnd.element, datesErrorMessageOrValid)

  return typeof datesErrorMessageOrValid === 'boolean'
}

export const getSemd224Entities = () => {
  const dateStart = new SemdDatePicker({
    id: SEMD224_ENTITY_NAMES.DATE_START,
    title: $t(SEMD224_ENTITY_NAMES.DATE_START),
  })

  const dateEnd = new SemdDatePicker({
    id: SEMD224_ENTITY_NAMES.DATE_END,
    title: $t(SEMD224_ENTITY_NAMES.DATE_END),
    isOptional: true,
  })

  dateStart.setCustomValidator(customDateValidator(dateStart, dateEnd))
  dateEnd.setCustomValidator(customDateValidator(dateStart, dateEnd))

  return [
    new SemdListSeparator({ title: window.t('semds.layout.inputs'), id: 'semds.layout.inputs' }),

    dateStart,
    dateEnd,

    new SemdTimePicker({
      id: SEMD224_ENTITY_NAMES.TIME_START,
      title: $t(SEMD224_ENTITY_NAMES.TIME_START),
    }),

    new SemdTimePicker({
      id: SEMD224_ENTITY_NAMES.TIME_END,
      title: $t(SEMD224_ENTITY_NAMES.TIME_END),
      isOptional: true,
    }),

    new SemdNsiMeasurement({
      id: SEMD224_ENTITY_NAMES.RADIATION_EXPOSURE,
      title: $t(SEMD224_ENTITY_NAMES.RADIATION_EXPOSURE),
      unitsTitle: $t('radiationExposureUnits'),
      nsiDictionary: 'metaCatalogRadiationExposure',
      defaultValueTitle: 'миллизиверт',
      isOptional: true,
    }),

    new SemdListSeparator({ title: window.t('semds.layout.lists'), id: 'semds.layout.lists' }),

    new SemdNsiDictionary({
      id: SEMD224_ENTITY_NAMES.DIRECTIONAL_MKB_DIAGNOSIS_GROUP,
      title: $t(SEMD224_ENTITY_NAMES.DIRECTIONAL_MKB_DIAGNOSIS_GROUP),
      nsiDictionary: NSI_DICTIONARIES.MKB10,
      isOptional: true,
    }),

    new SemdNsiDictionary({
      id: SEMD224_ENTITY_NAMES.CASE_VISIT_TYPE,
      title: $t(SEMD224_ENTITY_NAMES.CASE_VISIT_TYPE),
      nsiDictionary: NSI_DICTIONARIES.CASE_VISIT_TYPES,
      isOptional: true,
    }),

    new SemdNsiDictionary({
      id: SEMD224_ENTITY_NAMES.MEDICAL_CARE_PLACE,
      title: $t(SEMD224_ENTITY_NAMES.MEDICAL_CARE_PLACE),
      nsiDictionary: NSI_DICTIONARIES.MEDICAL_CARE_PLACES,
    }),

    new SemdNsiDictionary({
      id: SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH,
      title: $t(SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH),
      nsiDictionary: NSI_DICTIONARIES.INSTRUMENTAL_DIAGNOSTIC_RESEARCHES,
    }),

    new SemdNsiDictionary({
      id: SEMD224_ENTITY_NAMES.ULTRASOUND_MODE,
      title: $t(SEMD224_ENTITY_NAMES.ULTRASOUND_MODE),
      nsiDictionary: NSI_DICTIONARIES.ULTRASOUND_MODES,
      isOptional: true,
    }),

    new SemdNsiDictionary({
      id: SEMD224_ENTITY_NAMES.ECHO_IMAGE_QUALITY,
      title: $t(SEMD224_ENTITY_NAMES.ECHO_IMAGE_QUALITY),
      nsiDictionary: NSI_DICTIONARIES.ECHO_IMAGE_QUALITIES,
      isOptional: true,
    }),

    new SemdNsiDictionary({
      id: SEMD224_ENTITY_NAMES.DETECTED_PATHOLOGY,
      title: $t(SEMD224_ENTITY_NAMES.DETECTED_PATHOLOGY),
      nsiDictionary: NSI_DICTIONARIES.DETECTED_PATHOLOGIES,
      isOptional: true,
      multiple: true,
    }),

    new SemdListSeparator({ title: window.t('semds.layout.areas'), id: 'semds.layout.areas' }),

    new SemdArea({
      id: SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH_PROTOCOL_TEXT,
      title: $t(SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH_PROTOCOL_TEXT),
    }),

    new SemdArea({
      id: SEMD224_ENTITY_NAMES.CONCLUSION,
      title: $t(SEMD224_ENTITY_NAMES.CONCLUSION),
    }),

    new SemdArea({
      id: SEMD224_ENTITY_NAMES.RECOMMENDATIONS,
      title: $t(SEMD224_ENTITY_NAMES.RECOMMENDATIONS),
      isOptional: true,
    }),
  ]
}

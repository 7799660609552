import { SYSTEM_DISCOUNTS, DISCOUNT_TYPES } from './const'
export function getMaxPayerDiscount (payer) {
  const personal = getPersonalDiscount(payer)
  const accounted = getAccountedDiscount(payer)

  return personal.value > accounted.value ? personal : accounted
}
/**
 * @param payer
 * @returns {{reasonId: (number|null), value: number}}
 */
export function getPersonalDiscount (payer) {
  const reason = getDiscountReasonBySystemName(SYSTEM_DISCOUNTS.PERSONAL)

  return {
    value: extractPersonalDiscount(payer),
    reasonId: reason ? +reason.id : null,
  }
}

/**
 *
 * @param payer
 * @returns {{reasonId: (number|null), value: number}}
 */
export function getAccountedDiscount (payer) {
  const reason = getDiscountReasonBySystemName(SYSTEM_DISCOUNTS.ACCOUNTED)

  return {
    value: extractAccountedDiscount(payer),
    reasonId: reason ? +reason.id : null,
  }
}

/**
 * @param reasonName
 * @returns {{id: number, system_name: string, title: string} | undefined}
 */
function getDiscountReasonBySystemName (reasonName) {
  return gon.specific.discount_reasons.find((item) => {
    return item.system_name === reasonName
  })
}

/**
 * Системное имя причины скидки по id
 * @param {number} reasonId
 * @returns {string}
 */
export function getDiscountReasonsSystemNameByReasonId (reasonId) {
  if (!reasonId) return ''

  return (gon.specific.discount_reasons.find((item) => item.id === reasonId) || { system_name: '' }).system_name
}

/**
 * Для системных скидок вернёт объект с максимальным значением, типом скидки, значеним скидки
 * @param {number} reasonId
 * @param {number} maxSum
 * @returns {{maxValue: number, type: string, value: number}}
 */
export function getSystemDiscountByReasonId (reasonId, maxSum = 0) {
  const systemName = getDiscountReasonsSystemNameByReasonId(reasonId)
  const gonAccountedDiscount = +gon.specific.accounted_discount || 0
  const gonAccountedBonusPoints = +gon.specific.accounted_bonus_points || 0
  const gonPersonalDiscount = +gon.specific.client.discount || 0

  switch (systemName) {
    case SYSTEM_DISCOUNTS.ACCOUNTED:
      return {
        type: DISCOUNT_TYPES.PERCENT,
        value: gonAccountedDiscount,
        maxValue: 100,
      }

    case SYSTEM_DISCOUNTS.PERSONAL:
      return {
        type: DISCOUNT_TYPES.PERCENT,
        value: gonPersonalDiscount,
        maxValue: 100,
      }

    case SYSTEM_DISCOUNTS.BONUS:
      const maxBonusPoints = getMaxBonusPoints(maxSum, gonAccountedBonusPoints)

      return {
        type: DISCOUNT_TYPES.CURRENCY,
        value: gonAccountedBonusPoints > maxBonusPoints ? maxBonusPoints : gonAccountedBonusPoints,
        maxValue: maxBonusPoints,
      }
  }
}

/**
 * Рассчёт максимального количества баллов, которые можно использовать
 * @param {number} maxSum
 * @param {number} gonAccountedBonusPoints
 * @returns {number}
 */
const getMaxBonusPoints = (maxSum = 0, gonAccountedBonusPoints = 0) => {
  const personalDiscount = +gon.specific.client.discount * maxSum / 100
  const gonMaxDiscount = +gon.specific.max_bonus_discount || 100
  const maxBonusPoints = Math.ceil((maxSum - personalDiscount) * gonMaxDiscount / 100)

  if (gonAccountedBonusPoints < maxBonusPoints) {
    return gonAccountedBonusPoints
  }

  return maxBonusPoints
}

/**
 * @param payer
 * @returns {number}
 */
function extractPersonalDiscount (payer) {
  return +Utils.findValueByAliases(payer, ['discount', 'personalDiscount'], 0)
}

/**
 * @param payer
 * @returns {number}
 */
function extractAccountedDiscount (payer) {
  return +Utils.findValueByAliases(payer, ['accounted_discount', 'accountedDiscount', 'accumulated_discount'], 0)
}

import { ICatalog } from '@/_declarations/ICatalog'
import { TTinyMCEEditor } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/TTinyMCE'
import editorHelper from '@/plugins/dynamic_forms/custom_plugins/editorHelper'
import {
  AbstractSemdEntity,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/AbstractSemdEntity'

export class SemdEntity extends AbstractSemdEntity {
  static parseContent (id: string, templateHtml: string): string | ICatalog {
    /* @ts-ignore */
    const $templateHtml = $(`<div>${templateHtml}</div>`)
    const element = $templateHtml.find(`#${id}`)[0]
    if (!element) { return null }

    const value = element.dataset.value

    if (!value || value === 'undefined') { return null }

    try {
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }

  protected customValidator: (...args: any) => boolean

  element: HTMLElement

  id: string

  title: string

  value: unknown

  isOptional = false

  defaultValueTitle: string

  constructor ({
    id,
    title,
    value = null,
    isOptional = false,
    defaultValueTitle = '',
  }) {
    super()
    this.id = id
    this.title = title
    this.value = value
    this.isOptional = Boolean(isOptional)
    this.defaultValueTitle = defaultValueTitle
  }

  createElement (editor: TTinyMCEEditor): void {
    editor.insertContent(this.buildTemplate())
    this.linkWithElement(editor)
    window.Utils.updateTooltipsInstances(editor.getBody())
  }

  destroy (editor: TTinyMCEEditor): void {
    this.element = editor?.getBody().querySelector(`#${this.id}`) as HTMLElement
    if (!this.element) { return console.debug('Element not found') }
    if (!this.element.parentNode) {
      if (this.element.className.includes('semd-entity') ||
      this.element.className.includes('semd-area')
      ) {
        $(`#${this.element.id}`).remove()
        this.element = null
      }

      return
    }
    this.element.parentNode.removeChild(this.element)
    this.element = null
  }

  update (editor: TTinyMCEEditor, value: unknown): void {
    if (!this.element) { return console.debug('Element not found') }
    const element = editor?.getBody().querySelector(`#${this.id}`) as HTMLElement
    if (!element) { return console.debug('Element not found') }

    this.element = element
    this.value = value

    this.element.dataset.value = JSON.stringify(value)
    this.drawElementValue(value)
  }

  drawElementValue (value: unknown) {
    this.element.innerHTML = (value as ICatalog)?.title || ''
  }

  buildTemplate (value?: unknown): string {
    return `
      &nbsp;<span
        id="${this.id}"
        data-id="${this.id}"
        data-name="${this.title}"
        data-type="${this.id}"
        data-value="${value}"
        data-tooltip="${window.t('semds.semdEntityVariableTooltip')}"
        class="semd-entity mceNonEditable t-element"
      >&nbsp;</span>&nbsp;
    `.trim()
  }

  linkWithElement (editor: TTinyMCEEditor) {
    const element: HTMLElement =
      editor.getBody()?.querySelector(`#${this.id}`)
    if (!element) { return console.debug('Element not created') }

    this.element = element
    if (!this.element.dataset.value) { return }
    try {
      this.value = JSON.parse(this.element.dataset.value)
    } catch (e) { }

    editorHelper.setBogusBefore(this.element)
    editorHelper.setBogusAfter(this.element)
  }

  getContent () {
    return this.value
  }

  isValid () {
    if (this.customValidator) { return this.customValidator() }
    if (this.isOptional) { return true }

    return Boolean(this.value)
  }

  setCustomValidator (validator: (...args: any) => boolean) {
    this.customValidator = validator

    return this
  }
}

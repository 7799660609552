class InsertFixEntityListener {
  /**
   * insert data to tinymce to fix element (fix_list)
   */
  handle (event) {
    const element = event.element
    const value = event.value

    element.setAttribute('contenteditable', true)
    element.innerHTML = value
    const selection = tinymce.activeEditor.getDoc().getSelection()
    const range = tinymce.activeEditor.getDoc().createRange()
    selection.removeAllRanges()
    range.setStart(element.firstChild, element.firstChild.length)
    range.setEnd(element.firstChild, element.firstChild.length)
    selection.addRange(range)
  }
}

export default InsertFixEntityListener

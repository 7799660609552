import { createVueApp } from '@/vue_components/create_vue_app'
import SelectClinic from '@/vue_components/users/SelectClinic/SelectClinic'
import { createStore } from '@/vue_components/store/store'
import SelectClinicModal from '@/vue_components/users/SelectClinic/SelectClinicModal'

export const createSelectClinic = (el = '#vue_select_clinic') => {
  createVueApp({
    el,
    name: 'SelectClinicRoot',
    store: createStore(),
    render: (h) => h(SelectClinic, {
      props: {
        clinicId: gon.application.current_clinic && gon.application.current_clinic.id,
        userId: gon.application.current_user && gon.application.current_user.id,
      },
    }),
  })
}

export const createSelectClinicModal = (el = '#vue_select_clinic_modal') => {
  createVueApp({
    el,
    name: 'SelectClinicModalRoot',
    store: createStore(),
    render: (h) => h(SelectClinicModal, {
      props: {
        clinicId: gon.application.current_clinic && gon.application.current_clinic.id,
        userId: gon.application.current_user && gon.application.current_user.id,
      },
    }),
  })
}

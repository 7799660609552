<template>
  <modal
    modal-class="modal-window-select-legal-representative"
    :modal-visibility="visible"
    @close="close"
  >
    <template #header>
      {{ T.selection_of_legal_representative }}
    </template>

    <template #body>
      <span>
        {{ T.select_legal_representative }}
      </span>
      <items-list
        v-model="selectedLegalRepsentativeIds"
        v-bind="itemsListConfig"
      />
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal.vue'
import ItemsList from '@/vue_components/items_list.vue'

export default {
  name: 'ModalWindowSelectLegalRepresentative',

  components: {
    Modal,
    ItemsList,
  },

  props: {
    legalRepresentatives: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    visible: false,

    legalRepresentativeIds: [],
  }),

  computed: {
    selectedLegalRepsentativeIds: {
      set (ids) {
        this.legalRepresentativeIds = ids
        this.$pubSub.emitAsync('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.LEGAL_REPRESENTATIVE_ID', ids[0])
        this.$emit('selected-lr-id', ids[0])
      },

      get () {
        return this.legalRepresentativeIds
      },
    },

    itemsListConfig () {
      return {
        items: this.legalRepresentatives,
        minSelectedItems: 1,
        multiSelect: false,
        idItemField: 'id',
        displayedItemField: 'title',
      }
    },
  },

  beforeDestroy () {
    this.$pubSub.reset('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.VISIBLE')
  },

  mounted () {
    this.$pubSub.subscribe('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.VISIBLE', (visible) => {
      this.visible = visible
      this.legalRepresentativeIds = []
    })
  },

  methods: {
    close () {
      this.visible = false
      this.$pubSub.emitAsync('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.CLOSE')
    },
  },
}
</script>

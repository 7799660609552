/**
 *  use builder pattern director
 */
export class TemplateApplication {
  /**
   * @param builder
   * @returns {{run}|*}
   */
  build (builder) {
    builder.setMapping()
    builder.initComponents()

    return builder.create()
  }

  destroy (builder) {
    if (!builder.freeComponents) { return }
    builder.freeComponents()
  }
}

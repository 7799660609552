import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export const ConfirmationMixin = {
  methods: {
    /**
     * Обёртка для подтверждения удаления
     *
     * @param {string} text
     * @param {{
     *   title: string
     * }} [options]
     * @return {Promise<'confirm'> | Promise<{ cancel: boolean }>}
     */
    async confirmDelete (text = t('delete_1'), options) {
      return await MConfirm.error(text, options)
    },
  },
}

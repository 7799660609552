/**
 * Если необходимо, то можно ограничить
 * export const VALID_WORD_STYLES = ['color','font-size', 'font-style', ...].join(' ')
 * @type {string}
 */
export const VALID_WORD_ELEMENTS = 'b,strong,i,em,h1,h2,h3,p,ul,li,u,table,tr,td,th,style,align,font-style,font-size,color'

const isInvalidElements = (elementName) => {
  return ['v:shape', 'v:shapetype']
    .includes(elementName)
}

/**
 * Удаляет уже существующие невалидные элементы, скопированные из ворда
 */
export const removeInvalidTagsFromEditor = (rootContainer = document) => {
  const elementsToRemove = []

  const msoNormals = rootContainer.querySelectorAll('.MsoNormal')
  msoNormals
    .forEach((wordNode) => {
      wordNode.childNodes.forEach((cn) => {
        if (isInvalidElements(cn.localName)) {
          elementsToRemove.push(cn)
        }
      })
    })

  console.info(`Removed ${elementsToRemove.length} invalid tags`)

  elementsToRemove.forEach((cn) => cn.remove())
}

export const removeContainers = (rootContainer = document) => {
  const LAST = 1
  const container =
    rootContainer.querySelectorAll('.editor-body > .editor-mount > .template-html-container')[LAST]

  if (!container) { return }

  container.parentNode.innerHTML = container.querySelector('.editor-mount').innerHTML
}

export const removeMsoClasses = (rootContainer = document) => {
  rootContainer.querySelectorAll('[class*="Mso"], [style*="mso-"]').forEach((node) => {
    node.removeAttribute('class')
    node.removeAttribute('data-mce-style')
    node.removeAttribute('style')
  })
}

export const cleanUpEditor = (rootContainer = document) => {
  setTimeout(() => {
    removeInvalidTagsFromEditor(rootContainer)
  })

  setTimeout(() => {
    removeMsoClasses(rootContainer)
  })

  setTimeout(() => {
    removeContainers(rootContainer)
  })
}

/**
 * abstract
 * base class for listeners
 */
class Listener {
  constructor (container) {
    this.container = container
  }

  /**
   * event - event object
   */
  handle (event) {
    throw new Error('call abstact method')
  }
}

export default Listener

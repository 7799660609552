<template>
  <validation-wrapper
    class="m-buttons-group el-radio-button-fix-color"
    :errors="validationErrors"
    :fixed-height="fixedHeight"
  >
    <el-checkbox-group
      v-if="groupType === 'checkbox'"
      :value="value"
      :size="size"
      :disabled="disabled"
      fill="#3FAECA"
      text-color="#ffffff"
      :class="{ 'm-buttons__vertical': orientation === 'vertical' }"
      @input="$emit('input', $event)"
    >
      <slot v-for="item in items">
        <el-checkbox-button
          :key="`el-check:${item[attributeValue]}`"
          :label="item[attributeValue]"
        >
          <span class="font-size-13">{{ item[attributeLabel] }}</span>
        </el-checkbox-button>
      </slot>
    </el-checkbox-group>

    <el-radio-group
      v-else
      :value="value"
      :size="size"
      :disabled="disabled"
      :class="{ 'm-buttons-group__vertical': orientation === 'vertical' }"
      @input="$emit('input', $event)"
    >
      <slot v-for="item in items">
        <el-radio-button
          :key="`el-radio:${item[attributeValue]}`"
          :label="item[attributeValue]"
        >
          <span class="font-size-13">{{ item[attributeLabel] }}</span>
        </el-radio-button>
      </slot>
    </el-radio-group>
  </validation-wrapper>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'

export default {
  name: 'ButtonsGroup',
  components: { ValidationWrapper },
  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    groupType: {
      type: String,
      default: 'radio', // checkbox
    },

    validationErrors: {
      type: Array,
      default: () => [],
    },

    fixedHeight: {
      type: Boolean,
      default: true,
    },

    items: {
      type: Array,
      default: () => [],
    },

    value: {
      type: [String, Number, Boolean, Array],
      default: null,
    },

    size: {
      type: String,
      default: 'small',
    },

    min: {
      type: Number,
      default: 1,
    },

    max: {
      type: Number,
      default: 100,
    },

    disabled: Boolean,

    attributeLabel: {
      type: String,
      default: 'label',
    },

    attributeValue: {
      type: String,
      default: 'value',
    },

    orientation: {
      type: String,
      default: 'horizontal', // ['horizontal', 'vertical']
    },
  },
}
</script>

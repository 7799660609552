import Manager from './common/manager.js'

/**
 * @abstract
 */
export default class Component {
  /**
   * @param map
   */
  constructor (map = false, builder = false) {
    if (builder && builder.container) {
      this.container = builder.container
      this.dispatcher = this.container.get('dispatcher')
      this.store = this.container.get('store')
      this.entityManager = this.container.get('entity_manager')
      this.config = this.container.get('config')
      this.editor = this.container.get('editor')
    }
    if (map) {
      Object.keys(map).forEach((element) => {
        this[element] = map[element]
      })
    }

    //init manager
    this.manager = new Manager(map)
  }

  /**
   * @public
   * @param store
   * @param components
   */
  init (store, components) {
    this._store = store
    this._components = components
    if (this.autoRun) this.run()
    this._bindEvents()
  }

  /**
   * abstract methods
   */
  run () {}

  _bindEvents () {}

  _setParams () {}

  _loadStorages () {}
}

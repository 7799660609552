import VariablesList from '@/vue_components/variables_list/variables_list.js'
import Formatter from '@/services/variables_formatter/variables_formatter.js'
import Translator from '@/services/variables_formatter/translator'
import RubyFormatter from '@/lib/formatters/RubyFormatter'

export default class {
  constructor (params = {}) {
    const url = params.url
    this.editorName = params.editorName
    this.translationMap = {}
    this.onLoad = params.onLoad || function () {}
    this.onItemSelectedSpecificAction = params.onItemSelectedSpecificAction
    this.formattedVariableNames = new Map([
      ['@order&.sum', ['price']],
      ['@order&.final_sum', ['price']],
      ['@order&.paid_sum', ['price']],
      ['@dental_order&.sum', ['price']],
    ])
    this.rubyFormatter = new RubyFormatter()
    this.erbFormat = {
      regex: /<%=(?:&nbsp;| )*(?:".*"\s*%\s*)*@(.*?)&?\.(.*?)(?:&nbsp;| )*%>/gi,
      getVariable: (v1, v2) => [v1, v2],
      doFormat: (v) => {
        const originalVar = this.translator.detranslate(v)
        const variableName = this.formatVariable(`@${originalVar[0]}&.${originalVar[1]}`)

        return `<%= ${variableName} %>`
      },
    }

    this.legacyVariables = {
      client: {
        parent_name: T.parent_name,
        formatted_parent_birthdate: T.parent_birthdate,
      },
    }

    this.curlyFormat = {
      regex: /{{(?:&nbsp;| )*(.*?)\.(.*?)(?:&nbsp;| )*}}/gi,
      getVariable: (v1, v2) => [v1, v2],
      doFormat: (v) => {
        const translatedVar = this.translator.translate(v)

        return `{{ ${translatedVar.join('.')} }}`
      },
    }

    this.formatter = new Formatter({
      formats: {
        erb: this.erbFormat,
        curly: this.curlyFormat,
      },
    })

    this.variablesList = new VariablesList({
      onItemSelected: (v) => {
        if (typeof this.onItemSelectedSpecificAction === 'function' &&
          this.onItemSelectedSpecificAction(v) === false) {
          return
        }
        const formattedVariable = this.curlyFormat.doFormat(v.source)
        this.editor.insertContent(formattedVariable)
      },
    })

    this._getRemoteList(url)
      .then((res) => {
        const formattedList = this._formatList(res)
        this.variablesList.setList(formattedList)
        this.translator = new Translator({
          map: this.translationMap,
        })
        this.onLoad()
      }).catch((err) => { console.log(err) })
  }

  /**
   * @param {String} name
   * @return {String}
   */
  formatVariable (name) {
    if (this.formattedVariableNames.has(name)) {
      return this.rubyFormatter.formatValue(name, this.formattedVariableNames.get(name))
    }

    return name
  }

  _getRemoteList (url) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url,
        success (res) { resolve(res) },
        error (res) { reject(res.responseJSON) },
      })
    })
  }

  _formatList (originalList) {
    const resultList = []
    for (const category in originalList) {
      const originalObj = originalList[category]
      const resultObj = {
        source: category,
        label: T[category],
        children: [],
      }

      this.translationMap[category] = {}
      this.translationMap[category]['self_translation'] = T[category]
      for (const item in originalObj) {
        resultObj.children.push({
          source: item,
          label: originalObj[item],
        })
        this.translationMap[category][item] = originalObj[item]
      }
      resultList.push(resultObj)
    }

    for (const category in this.legacyVariables) {
      if (this.translationMap[category] === undefined) {
        this.translationMap[category] = {}
        this.translationMap[category]['self_translation'] = T[category]
      }

      this.translationMap[category] = Object.assign(this.translationMap[category], this.legacyVariables[category])
    }

    return resultList
  }

  _clearHTML (text) {
    const unescapeMap = {
      '&lt;': '<',
      '&gt;': '>',
      '&amp;': '&',
    }

    return text.replace(/(&lt;|&gt;|&amp;)/g, (match, p1) => unescapeMap[p1])
  }

  originalize () {
    const originalizedText = this.getOriginalContent()
    this.editor.setContent(originalizedText)

    return originalizedText
  }

  visualize () {
    const visibleText = this.getVisibleContent()
    this.editor.setContent(visibleText)

    return visibleText
  }

  getOriginalContent () {
    const editorText = this.editor.getContent()

    return this.formatter.from('curly').to('erb', this._clearHTML(editorText))
  }

  getVisibleContent () {
    const editorText = this.editor.getContent()

    return this.formatter.from('erb').to('curly', this._clearHTML(editorText))
  }

  show () {
    this.variablesList.show()
  }

  hide () {
    this.variablesList.hide()
  }

  checkForInvalidVariables () {
    const currentList = this.variablesList.getList()
    const originalText = this.formatter.from('curly')
      .to('erb', this._clearHTML(this.editor.getContent()))
    const editorVariables = this.formatter.from('erb').getVariables(originalText)
    const invalidVariables = []

    for (const editorVariable of editorVariables) {
      let l = this.legacyVariables
      let skipCheck = true

      for (const variable of editorVariable) {
        if (typeof l === 'object' && variable in l) {
          l = l[variable]
        } else {
          skipCheck = false
          break
        }
      }

      if (skipCheck) continue

      let list = currentList
      for (const variable of editorVariable) {
        // variable = 'client'
        const res = list.find((listVariable) => listVariable.source === variable)
        if (res) {
          if (res.children) {
            list = res.children
          } else {
            list = []
          }
        } else {
          invalidVariables.push(editorVariable)
          break
        }
      }
    }

    return invalidVariables
  }

  get editor () {
    return tinymce.get(this.editorName)
  }
}

import Listener from './listener'
export default class DeleteEntity extends Listener {
  /**
   * insert data to tinymce to dynamic element
   */
  handle (event) {
    let manager = this.container.get('entity_manager')
    let entity = manager.getEntity(event.element)
    if (!entity) {
      return
    }
    manager.removeElement(entity)
  }
}

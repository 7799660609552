import BaseTree from '../base_tree'
import Events from './events'

export default class EntityTree extends BaseTree {
  /**
   * set expanded selected and children selected node
   */
  convertData (items) {
    const expandRecursive = (children) => {
      let res = false
      children.forEach((child) => {
        if (child.children && expandRecursive(child.children)) {
          child.expanded = true
          res = true
        }
        if (child.selected) {
          child.expanded = true
          res = true
        }
      })

      return res
    }
    let result = []
    let nodes = items[0].children
    if (!nodes) return result

    expandRecursive(nodes)
    result.push(items[0])

    return result
  }

  /**
   * builds tree
   */
  build (tree, selectedFolders, mountPoint) {
    this.mountPoint = mountPoint
    $(this.mountPoint).fancytree({
      checkbox: (event, data) => data.node.folder,
      beforeExpand: (event, data) => data.node.key !== '_1',
      postProcess: (event, data) => {
        data.result = this.convertData(data.response)
      },
      icon: this.icon.bind(this),
      source: tree,
      selectMode: 2,
      select: this.selectNode.bind(this),
      init (event, data) {
        data.tree.getRootNode().sortChildren(null, true)
      },
    })
    this.tree.reload(tree)
    this._setConnectors()
  }

  /**
   * private
   */
  _postConstruct () {
    (new Events()).pullEvents(this)
    this._setMountPoint()
  }
}

import baseTemplateComponent from '../base_component'
import actions from '../../actions'

export default class MathEditor extends baseTemplateComponent {
  constructor (params, builder) {
    super(params, builder)
    this._buildModal()
    this._bindEvents()
  }

  _buildModal () {
    this._window = $('#form_builder-math-editor-modal')
    this._windowBody = this._window.find('.modal-body')
    this._formulaInput = $('#math-form_formula')
    this._window.megamodal({btnResize: false})
  }

  _bindEvents () {
    this._window.find('.modal-save').on('click', this.save.bind(this))
    this._window.find('form').on('submit', (e) => e.preventDefault())

    this.controlButtons.forEach((button) => {
      button.onclick = () => {
        const resultInput = document.querySelector('#math-form_formula')
        resultInput.value = resultInput.value + button.children[0].innerHTML
      }
    })
  }

  show (entity) {
    this.entity = entity
    this._formulaInput
      .val('')
      .val(entity.data.value)

    this.update()

    this._window.megamodal('show')
  }

  update () {
    this.variablesContainer.innerHTML = ''
    const deletedVars = this.entityManager.deletedEntities.filter((variable) => variable.type === 'variables')
    let variablesStorage = this.store.getState().variables.filter((variable) => {
      return !deletedVars.find((deleted) => deleted.id === variable.id)
    })
    let calcStorage = this.store.getState().calculate
    let currentF = calcStorage.find(
      (calc) => calc.id === parseInt(this.entity.id),
    )

    //protection from closure formulas
    calcStorage = calcStorage.filter((calc) => {
      var rexExCalc = new RegExp(currentF.name)
      let closure = calc.value.match(rexExCalc, 'g')
      if (closure || calc.id == +currentF.id) return false

      return calc
    })

    let elementStorage = [...calcStorage, ...variablesStorage]

    if (elementStorage && elementStorage.length > 0) {
      elementStorage.forEach((variable) => {
        let button = document.createElement('button')
        button.innerHTML = variable.name
        button.type = 'button'
        button.classList.add('btn', 'btn-primary', 'btn-sm')

        button.onclick = () => {
          let value
          if (variable.name[0] === 'F') {
            value = `(${variable.name})`
          } else {
            value = variable.name
          }
          let oldValue = this._formulaInput.val()
          this._formulaInput.val(oldValue + value)
        }

        this.variablesContainer.appendChild(button)
      })
    }
  }

  save (event) {
    event.preventDefault()

    let result = this._formulaInput.val()
    this.store.dispatch({
      type: this.entity.actions.UPDATE,
      payload: {
        id: this.entity.id,
        value: result,
      },
    })

    this._window.megamodal('hide')
  }
}

import baseTemplateComponent from '../base_component'

export default class ContextMenu extends baseTemplateComponent {
  /**
   * @public build context menu
   * @var editor - tinyMce editor
   * e - emmit event
   */
  build (editor, e, target = false) {
    e.preventDefault()
    this.notClose = this.notClose || false
    this.dispatcher = this.container.get('dispatcher')
    const _target = target || e.target

    // с chrome версии 109.0.5414.75 нет path
    const protectedPath = e?.path || []
    const clickPath = [_target, ...protectedPath.slice(0, 5)]

    this.target = clickPath.find((t) => {
      this.entity = this.entityManager.getEntity(t)

      return this.entity
    })

    if (!this._checkEmmit(this.target)) {
      return
    }
    this.forceDelete()
    if (this.create(e) && this.window) {
      document.querySelector('body').appendChild(this.window)
      this._postCreate()
      //set flag on open correnct data
      this.openedData = true
    }
  }

  /**
   * check is element call context menu
   * @param target - HTML element
   * @return boolean
   */
  _checkEmmit (target) {
    if (!this.entity) return false
    let emmit = false
    const classList = target.classList
    this.contextMEmitClass.split(',').forEach((item) => {
      if (classList.contains(item)) {
        emmit = true
      }
    })

    return emmit
  }

  /**
   * delete with validate flag not close flag
   */
  delete () {
    if (this.resolveRemove()) {
      this.remove()
    }
  }

  /**
   * check resolved delete delete element
   */
  resolveRemove () {
    return true
  }

  /**
   * delete without close flag
   */
  forceDelete () {
    this.remove()
  }

  /**
   * final remove
   */
  remove () {
    if (!this.window) {
      return false
    }
    this.saveMenuCoord = {
      left: this.window.style.left,
      top: this.window.style.top,
    }
    this.window.remove()
    this.openedData = false
  }

  /**
   * @private
   * @var elem - event emitter
   */
  _bindDragAndDrop (elem) {
    const moveAt = (e, elem, pos) => {
      elem.style.left = e.pageX - pos.x + 'px'
      elem.style.top = e.pageY - pos.y + 'px'
    }

    const modalHead = elem.querySelector('.contexted-head')
    modalHead.onmousedown = (e) => {
      // if (!e.target.classList.contains('contexted-head')) return
      if (e.target.nodeName === 'INPUT') return
      e.preventDefault()
      const position = { x: e.offsetX, y: e.offsetY }
      document.body.appendChild(elem)
      document.onmousemove = (e) => moveAt(e, elem, position)
      elem.onmouseup = () => {
        document.onmousemove = null
        elem.onmouseup = null
      }
    }
  }

  /**
   * @private
   */
  //все константы в этой функции - это размеры элементов, которые нельзя получить при помощи функции css()
  _adjustContextMenu (menu) {
    menu = $(menu)
    if (menu.find('.btn-container').length === 0) { //для модалки без fancytree
      let menuHeight = 0
      let elemCount = $(menu[0]).find('li').length
      const elemHeight = 25.6
      const headHeight = 35

      if (menu.find('.diagnose-form').length) {
        elemCount += menu.find('.diagnose-form .checkbox-container').length
        menuHeight = 180
      }
      const documentHeight = document.body.offsetHeight
      const documentWidth = document.body.offsetWidth
      const Yposition = parseInt(menu.css('top'))
      const Xposition = parseInt(menu.css('left'))
      menuHeight += Math.min(elemCount, 16) * elemHeight + headHeight
      const menuWidth = parseInt(menu.css('min-width'))
      let newYposition = documentHeight - Yposition - menuHeight > 0 ? Yposition : Yposition - menuHeight
      const newXposition = documentWidth - Xposition - menuWidth > 0 ? Xposition : Xposition - menuWidth

      if (newYposition < 0) newYposition = 0

      menu.css('top', newYposition)
      menu.css('left', newXposition)
    }
  }

  _setMenuPositon (e) {
    if (!this.savePosition || !this.saveMenuCoord) {
      this.window.style.left = e.pageX + 'px'
      this.window.style.top = e.pageY + 'px'
    } else {
      this.window.style.left = this.saveMenuCoord.left
      this.window.style.top = this.saveMenuCoord.top
    }
  }

  /**
   * @abstract
   * @private
   */
  _postCreate () {}
}

<template>
  <modal
    v-if="visible"
    :modal-visibility="visible"
    :modal-class="'template_records_based_on_previous_modal'"
    @close="visible = false"
  >
    <div slot="header">
      {{ T.select_one_of_the_previous_protocols }}
    </div>
    <div slot="body">
      <div class="controls_container">
        <input
          v-model="search"
          class="form-control mb-1"
          :placeholder="T.search"
        >
        <input
          v-model="showOnlyMine"
          type="checkbox"
          class="mine_checkbox checkbox"
        >
        <span>{{ T.only_mine }}</span>
      </div>
      <div class="list_container">
        <table class="table table-striped table-hover">
          <tbody>
            <tr
              v-for="entry in entries"
              v-show="filter(entry)"
              :key="entry.id"
              @click="handleClick(entry)"
            >
              <td class="date">
                {{ entry.formatted_date }}
              </td>
              <td class="title">
                {{ entry.title }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../modal.vue'

export default {
  name: 'BasedOnPreviousModal',
  components: {
    Modal,
  },
  data: () => ({
    visible: false,
    entries: [],
    currentUserId: null,
    showOnlyMine: true,
    cached: false,
    search: '',
  }),
  created () {
    this.$pubSub.subscribe('TEMPLATE_RECORDS.BASED_ON_PREVIOUS_MODAL.HIDE', () => { this.visible = false })
    this.$pubSub.subscribe('TEMPLATE_RECORDS.BASED_ON_PREVIOUS_MODAL.SHOW', () => {
      this.visible = true
      this.currentUserId = gon.application.current_user.id
      if (!this.cached) {
        $.ajax({
          url: Routes.filter_entries_path(),
          method: 'GET',
          data: {
            client: {
              id: gon.specific.client.id,
            },
          },
          success: (data) => {
            const currentEntry = gon.specific.entry
            this.entries = data.filter((e) => e.id !== currentEntry.id)
            this.cached = true
          },
          error (err) { console.log(err.responseText) },
        })
      }
    })
  },
  methods: {
    handleClick (entry) {
      this.visible = false
      this.$pubSub.emitAsync('TEMPLATE_RECORDS.BASED_ON_PREVIOUS_MODAL.ITEM_SELECTED', entry)
      Notificator.success(T.protocol_has_been_rewritten)
    },
    filter (entry) {
      return (!this.showOnlyMine ||
        (this.showOnlyMine && entry.user_id === this.currentUserId)) &&
        entry.title.toLowerCase().includes(this.search.toLowerCase())
    },
  },
}
</script>

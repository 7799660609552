import { InsertDynamicEntity } from '../../events'
export default class EventManager {
  constructor () {
    this.instance = this
  }

  pullEvents (context) {
    context['activateNode'] = this.activateNode
    context['icon'] = this.icon
    context['clickNode'] = this.clickNode
  }

  icon (event, data) {
    if (data.node.key === '_1') {
      return 'fad fa-tree'
    }
    if (!data.node.folder) {
      return 'fad fa-minus'
    }
  }

  clickNode (event, data) {
    if (data.node.folder) {
      return
    }
    this.dispatcher = this.container.get('dispatcher')
    let value = data.node.title
    let eventEntity = new InsertDynamicEntity(value, this.sourceElement)
    this.dispatcher.dispatch(eventEntity)
  }

  /**
   * difficulty transfer functon
   */
  activateNode (event, data) {
  }
}

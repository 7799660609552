import { GLOBAL_VARIABLES_LIST,
  APPOINTMENT_VARIABLES_LIST,
  CLIENTS_DISPATCH_VARIABLES_LIST,
  CLIENTS_BIRTHDAY_VARIABLES_LIST,
  CLIENTS_FEEDBACK_VARIABLES_LIST,
  SERVICES_STATUS_VARIABLES_LIST,
  APPOINTMENT_CONFIRMATION_VARIABLES_LIST,
  PREVIEW_SMS_PATH,
  CATEGORY_PATH,
  CATEGORY_TYPE,
  SAVE_TEMPLATE_PATH,
  REWRITE_TEMPLATE_PATH } from './constants.js'

export const TEMPLATE = {
  variablesList: {
    variables: GLOBAL_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_SMS_PATH,
  catSelector: false,
}

export const CLIENTS_DISPATCH = {
  variablesList: {
    variables: CLIENTS_DISPATCH_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_SMS_PATH,
  templateType: 'clients_dispatch',
  saveTemplateUrl: SAVE_TEMPLATE_PATH,
  rewriteTemplateUrl: REWRITE_TEMPLATE_PATH,
  categoryUrl: CATEGORY_PATH,
  categoryType: CATEGORY_TYPE,
}

export const APPOINTMENT_NOTIFY = {
  variablesList: {
    variables: APPOINTMENT_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_SMS_PATH,
  templateType: 'appointment',
  saveTemplateUrl: SAVE_TEMPLATE_PATH,
  rewriteTemplateUrl: REWRITE_TEMPLATE_PATH,
  categoryUrl: CATEGORY_PATH,
  categoryType: CATEGORY_TYPE,
}

export const APPOINTMENT_REMIND = {
  variablesList: {
    variables: APPOINTMENT_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_SMS_PATH,
  templateType: 'appointment',
  saveTemplateUrl: SAVE_TEMPLATE_PATH,
  rewriteTemplateUrl: REWRITE_TEMPLATE_PATH,
  categoryUrl: CATEGORY_PATH,
  categoryType: CATEGORY_TYPE,
}

export const CLIENTS_BIRTHDAY = {
  variablesList: {
    variables: CLIENTS_BIRTHDAY_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_SMS_PATH,
  templateType: 'clients_birthday',
  saveTemplateUrl: SAVE_TEMPLATE_PATH,
  rewriteTemplateUrl: REWRITE_TEMPLATE_PATH,
  categoryUrl: CATEGORY_PATH,
  categoryType: CATEGORY_TYPE,
}

export const SERVICES_STATUS = {
  variablesList: {
    variables: SERVICES_STATUS_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_SMS_PATH,
  templateType: 'services_status',
  saveTemplateUrl: SAVE_TEMPLATE_PATH,
  rewriteTemplateUrl: REWRITE_TEMPLATE_PATH,
  categoryUrl: CATEGORY_PATH,
  categoryType: CATEGORY_TYPE,
}

export const CLIENTS_FEEDBACK = {
  variablesList: {
    variables: CLIENTS_FEEDBACK_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_SMS_PATH,
  templateType: 'clients_feedback',
  saveTemplateUrl: SAVE_TEMPLATE_PATH,
  rewriteTemplateUrl: REWRITE_TEMPLATE_PATH,
  categoryUrl: CATEGORY_PATH,
  categoryType: CATEGORY_TYPE,
}

export const APPOINTMENT_CONFIRMATION = {
  variablesList: {
    variables: APPOINTMENT_CONFIRMATION_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_SMS_PATH,
  templateType: 'appointment_confirmation',
  saveTemplateUrl: SAVE_TEMPLATE_PATH,
  rewriteTemplateUrl: REWRITE_TEMPLATE_PATH,
  categoryUrl: CATEGORY_PATH,
  categoryType: CATEGORY_TYPE,
}

import { SEMD_TYPE, SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import { getSemd119Entities } from '@/vue_apps/Protocols/SemdProtocolEditor/entities/Semd119/getSemd119Entities'
import { ISemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/ISemdEntity'
import { getSemd224Entities } from '@/vue_apps/Protocols/SemdProtocolEditor/entities/Semd224/getSemd224Entities'

export const getSemdEntitiesBySemdType = (semdType: keyof typeof SEMD_TYPE_ENUM): ISemdEntity[] => {
  switch (semdType) {
    case SEMD_TYPE.CONSULTATION: return getSemd119Entities() as ISemdEntity[]
    case SEMD_TYPE.INSTRUMENTAL_RESEARCH_PROTOCOL: return getSemd224Entities() as ISemdEntity[]
    default: return []
  }
}

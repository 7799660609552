import { applicationConf, backend, frontend } from './config'

const PADDING_SO_THAT_THE_BUTTONS_IN_THE_FOOTER_DO_NOT_MOVE_OUT_OF_THE_SCREEN = 33
const FRONTEND_EDITOR_OFFSET = 133
const BACKEND_EDITOR_OFFSET = FRONTEND_EDITOR_OFFSET + PADDING_SO_THAT_THE_BUTTONS_IN_THE_FOOTER_DO_NOT_MOVE_OUT_OF_THE_SCREEN

export const getMap = (type) => {
  const map = {}
  if (type === 'frontend') {
    map.mode = 'edit'
    map.modalWindow = document.querySelector('#frontend-form')
    map.modalContainer = document.querySelector('#frontend-form__container')
    map.editorContainer = document.querySelector('.frontend-form__editor-wrap')
    map.editorToolbar = document.querySelector('#frontend-toolbar')
    map.editorWrap = document.querySelector('#editor-body')
    map.editorMountPoint = document.querySelector('#frontend-mount-point')
    map.editorId = 'frontend-mount-point'
    map.editorSettings = frontend.editor
    map.contextMEmitClass = applicationConf.listClass
    map.EDITOR_OFFSET = FRONTEND_EDITOR_OFFSET // Я проклинаю того, кто сделал эту хуйню
    map.modalBody = map.modalWindow.querySelector('.modal-body')
  }

  if (type === 'backend') {
    map.mode = 'constructor'
    map.modalWindow = document.querySelector('#form_builder')
    map.modalContainer = document.querySelector('.form_builder__container')
    map.editorContainer = document.querySelector('.form_builder__editor_wrap')
    map.editorToolbar = document.querySelector('#toolbar')
    map.editorWrap = document.querySelector('#form_builder__editor-body')
    map.editorMountPoint = document.querySelector('#form_builder__mount-point')
    map.editorId = 'form_builder__mount-point'
    map.editorSettings = backend.editor
    map.recordIdInput = document.querySelector('#template_record_id')
    map.contextMEmitClass = `${applicationConf.baseClass},entries_variable`
    map.EDITOR_OFFSET = BACKEND_EDITOR_OFFSET // Я проклинаю того, кто сделал эту хуйню
    map.modalBody = map.modalWindow.querySelector('.modal-body')
  }

  if (type === 'panel') {
    map.math = {
      autoRun: true,
      container: document.querySelector('#math-panel-container'),
      controlButtons: document.querySelectorAll('.math-panel__control'),
      resultInput: document.querySelector('#math-form_formula'),
      saveButton: document.querySelector('#math-save'),
      mathInputId: document.querySelector('#math-id'),
      variablesContainer: document.querySelector('#variables-container'),
      form: document.querySelector('#math-form'),
    }
    map.list = {
      autoRun: true,
      container: document.querySelector('#list-panel-container'),
      saveButton: document.querySelector('#save-list'),
      addNewValueButton: document.querySelector('#add-list-item'),
      newListItem: document.querySelector('#list-form_new_value'),
      fixListContainer: document.querySelector('#items-list'),
      form: document.querySelector('#list-form'),
    }
    map.variables = {
      autoRun: true,
      container: document.querySelector('.form_builder__adjust-variable'),
      variableValueInput: document.querySelector('#variable-form_value'),
      saveButton: document.querySelector('#variable-save'),
      form: document.querySelector('#variables-form'),
    }
  }

  return map
}

import InsertDynamicEntityListener from './insert_dynamic_entity'
import InsertFixEntityListener from './insert_fix_entity'
import ShowPanelListener from './show_panel'
import UpdateFormulasListener from './update_formulas'
import BuildEntryVaiableModal from './build_entry_variable_modal'
import BuildPrintAreaModal from './build_print_area_modal'
import DeleteEntity from './delete_entity'

export const registerListeners = (container) => {
  container.add('insert_dynamic_entity', (new InsertDynamicEntityListener(container)))
  container.add('insert_fix_entity', (new InsertFixEntityListener(container)))
  container.add('show_panel', (new ShowPanelListener(container)))
  container.add('update_formulas', (new UpdateFormulasListener(container)))
  container.add('build_entry_variable_modal', (new BuildEntryVaiableModal(container)))
  container.add('delete_entity', (new DeleteEntity(container)))
  container.add('build_print_area_modal', (new BuildPrintAreaModal(container)))
}

export const unregisterListeners = (container) => {
  if (!container.removeComponent) { return }
  container.removeComponent('insert_dynamic_entity')
  container.removeComponent('insert_fix_entity')
  container.removeComponent('show_panel')
  container.removeComponent('update_formulas')
  container.removeComponent('build_entry_variable_modal')
  container.removeComponent('delete_entity')
  container.removeComponent('build_print_area_modal')
}

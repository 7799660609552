import { el, mount } from 'redom'
import { forEachObjIndexed } from 'ramda'

class Modal {
  // TODO params
  constructor (params) {
    this.modal = ModalFactory({
      title: t('insert_variables'),
      showButtons: false,
      fullscreenButton: false,
    })
    this.body = this.modal[0].querySelector('.modal-body')
    this.saveButton = this.body.querySelector('.modal-save')
    this._setParams(params)
    this._afterCreate()
  }

  /**
   * build body
   * abstract
   */
  mountBody () {
    throw new Error('this is abstract behavior')
  }

  /**
   * base behavior
   */
  _afterCreate () { }

  /**
   * base behavior
   */
  _setParams () {}

  /**
   * base behaviour
   */
  show () {
    this.modal.megamodal('show')
  }

  /**
   * base behaviour
   */
  hide () {
    this.modal.megamodal('hide')
  }
}

export default class EntryVariablesModal extends Modal {
  _setParams (container) {
    this.loaded = false
    this.container = container
    this.entityManager = this.container.get('entity_manager')
  }

  /**
   *
   */
  createList (data) {
    forEachObjIndexed((value, key) => {
      let list = el('ul', { style: { display: 'none' } })
      let item = el('a', t(key + '_protocol'))
      item.onclick = (e) => {
        event.preventDefault()
        let display = list.style.display
        list.style.display = display === 'none' ? '' : 'none'
      }
      mount(this.body, item)
      mount(this.body, el('br'))
      this.createListItems(list, value, key)
      mount(this.body, list)
    })(data)
  }

  /**
   *
   */
  createListItems (mountPoint, items, key) {
    forEachObjIndexed((transl, attr) => {
      let val = key + '&.' + attr
      let itemCategory = el('button', transl, {
        'data-name': val,
        style: {
          border: 'none',
          background: 'none',
          color: '#34a0bc',
        },
      })
      itemCategory.onclick = (e) => {
        e.preventDefault()
        let value = e.target.getAttribute('data-name')
        let title = e.target.innerText
        let type = this.container.get('config').entries_variable.dataType
        this.entityManager.build(type, {
          data: {
            value,
            title,
          },
        })
        this.hide()
      }
      mount(mountPoint, el('li', itemCategory))
    })(items)
  }

  mountBody () {
    if (this.loaded) {
      return
    }
    this.container.get('api').fetchEntryAttributes()
      .then((data) => this.createList(data))
      .then(() => {
        this.loaded = true
      })
  }
}


export const getDefaultIfNotExist = (currentValue, defaultValue) => currentValue === null || currentValue === undefined
  ? defaultValue
  : currentValue

export const getPrintingToolsFromConfiguration = (gS) => ({
  configuration_print_entry_info: gS.configuration_print_entry_info,
  configuration_print_entry_title: gS.configuration_print_entry_title,
  configuration_print_clinic_header: gS.configuration_print_clinic_header,
  configuration_print_doctor_signature: gS.configuration_print_doctor_signature,
})

export const getPrintingToolsFromItem = (item) => ({
  print_entry_info: item.print_entry_info,
  print_entry_title: item.print_entry_title,
  print_clinic_header: item.print_clinic_header,
  print_doctor_signature: item.print_doctor_signature,
})

/**
 * Вернет настройки печати.
 * Если настройки печати nullевые, то возмет настройки из конфигурации
 *
 * @param {{
 *   print_entry_info: Boolean,
 *   configuration_print_entry_info: Boolean,
 *   print_entry_title: Boolean,
 *   configuration_print_entry_title: Boolean,
 *   print_clinic_header: Boolean,
 *   configuration_print_clinic_header: Boolean,
 *   print_doctor_signature: Boolean,
 *   configuration_print_doctor_signature: Boolean
 *  }} gS - gon.specific
 *
 * @return {{
 *  clinicHeader: Boolean,
 *  entryInfo: Boolean,
 *  entryTitle: Boolean,
 *  doctorSignature: Boolean
 * }}
 */
export const getPrintingToolsValues = (gS) => {
  return {
    entryInfo: getDefaultIfNotExist(gS.print_entry_info, gS.configuration_print_entry_info),
    entryTitle: getDefaultIfNotExist(gS.print_entry_title, gS.configuration_print_entry_title),
    clinicHeader: getDefaultIfNotExist(gS.print_clinic_header, gS.configuration_print_clinic_header),
    doctorSignature: getDefaultIfNotExist(gS.print_doctor_signature, gS.configuration_print_doctor_signature),
  }
}

export const setPrintingToolsValuesToDOM = (printingToolsValues) => {
  const printingMap = {
    print_entry_info: 'entryInfo',
    print_entry_title: 'entryTitle',
    print_clinic_header: 'clinicHeader',
    print_doctor_signature: 'doctorSignature',
  }

  Object.entries(printingMap).forEach(([key, value]) => {
    $(`[name="entry[${key}]"]`).val(Number(printingToolsValues[value]))
  })
}

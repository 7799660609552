import baseTemplateComponent from '../base_component'
import UserException from '../exeptions'

export default class Modal extends baseTemplateComponent {
  constructor (params, builder = false) {
    super(params, builder)
    if (!this.modalWindow) throw new UserException('modal window not defined')
    this.saveButton = this.modalWindow.querySelector('.modal-save')
    this.modalFooter = $(this.modalWindow.querySelector('.modal-footer'))
    this.closeButton = this.modalWindow.querySelector('.modal-header-close')
    this.modalBody = $(this.modalWindow.querySelector('.modal-body'))
    this.contextMenu = this.container.get('contextMenu')
  }

  /**
   * @returns {boolean}
   */
  show () {
    return false
  }

  /**
   * @abstract
   * @protected
   */
  _open () {
    this.contextMenu.forceDelete()
    this.forceClose = false
    $(this.modalWindow).modal('show')
    // start spin
    $(this.modalBody).loadSpin('start')
  }

  /**
   * @private
   */

  start (item) {
    this._open()
    this.lazyLoad((result) => {
      this.item = result
      $(this.modalBody).loadSpin('stop')
      this.load(result)
    }, item)
  }

  //lazy load template
  lazyLoad (cb, item) {
    let request

    if (Utils.isPromise(item)) {
      request = item
    } else {
      request = new Promise((resolve) => {
        $.ajax({
          url: Routes.document_type_path(item.id),
          success: resolve,
        })
      })
    }

    request
      .then(cb)
      .catch((error) => {
        $(this.modalBody).loadSpin('stop')
        alert(t('errors.error_has_occurred'))
        console.error(error)
      })
  }
}

import BaseFormatter from '@/lib/formatters/BaseFormatter'

/**
 * This formatter transforms provided values as property names to use them in ERB templates.
 * When adding a rule to this formatter, you should remember that it should return a Ruby code,
 * that will format given variable.
 * First parameter to all rules will be a string with var name in it.
 */
export default class RubyFormatter extends BaseFormatter {
  /**
   *
   * @param {string} varName
   */
  price (varName) {
    return this.format(varName, '%.2f')
  }

  format (varName, formatString) {
    return `"${formatString}" % ${varName}`
  }
}

RubyFormatter.namespace = 'ruby'

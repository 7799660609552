import { last } from 'ramda'

export default class EntityFactory {
  constructor (params, builder) {
    this.container = builder.container
    this.config = this.container.get('config')
    this.store = this.container.get('store')
  }

  /**
   * build entity
   */
  build (type, data = false) {
    let options = this.config[type]
    this.store.dispatch({
      type: options.actions.ADD,
      payload: {
        title: options['title'],
        alias: options['alias'],
        data,
      },
    })

    let addedEntity = last(this.store.getState()[type])

    let entityData = Object.assign({}, addedEntity, data)
    options.entityClass.createElement(entityData, options)

    return addedEntity
  }
}

import BaseEditor from './base_editor'
import { toolbar3Params } from '@/plugins/dynamic_forms/configuration/config'
import { debounce } from 'lodash'
import FrontModal from '@/plugins/dynamic_forms/components/modal/front_entries_modal'
import { specSymbolsSanitize } from '@/specific/entries/submit_sanitizer'
import { ProtocolDraftStorage } from '@/plugins/dynamic_forms/components/editor/ProtocolDraftStorage'

export default class EditModeEditor extends BaseEditor {
  contentWasChanging = false

  debouncedSaveToLocalStorage = debounce(this.saveToLocalStorage.bind(this), 1000)

  isFromScratch () {
    return !gon.specific.entry.template_html && !this.protocolId
  }

  prepareEditor () {
    this.entityManager.showValues()
  }

  bindEditorEvents (editor) {
    super.bindEditorEvents(editor)
    editor.on('change', this._handleChange.bind(this))
    editor.on('click', this.contextMenu.delete.bind(this.contextMenu))
    editor.on('undo', this.setEntityEvents.bind(this))
    editor.on('redo', this.setEntityEvents.bind(this))
    editor.on('keyup', this.onKeyUp.bind(this))
    editor.on('mouseup', this.onMouseUp.bind(this))

    this.contentWasChanging = false
    editor.on('input', () => {
      if (this.contentWasChanging) { return }
      this.contentWasChanging = true
      Services.pubSub.emit('setProtocolChanged')
    })

    Services.pubSub.unsubscribeAll('frontendEditorListsChanged')
    Services.pubSub.unsubscribeAll('frontendEditorListsChangedForce')
    Services.pubSub.subscribe('frontendEditorListsChanged', this.debouncedSaveToLocalStorage.bind(this))
    Services.pubSub.subscribe('frontendEditorListsChangedForce', this.saveToLocalStorage.bind(this))
  }

  _handleInit (editor) {
    super._handleInit(editor)
    const leftMargin = store.get('edit_mode_lmargin')
    if (leftMargin) {
      this.modalContainer.style.marginLeft = leftMargin
    }
  }

  setEntityEvents () {
    this.entityManager.showValues()
  }

  changeEditorPosition () {
    let newLeftMargin = ''
    const leftMargin = this.modalContainer.style.marginLeft
    if (leftMargin === '0px') {
      newLeftMargin = 'auto'
    } else {
      newLeftMargin = '0px'
    }
    this.modalContainer.style.marginLeft = newLeftMargin
    store.set('edit_mode_lmargin', newLeftMargin)
  }

  _handleChange () {
    this.update()
    this.debouncedSaveToLocalStorage()
  }

  onKeyUp () {
    this.debouncedSaveToLocalStorage()
  }

  onMouseUp () {
    this.debouncedSaveToLocalStorage()
  }

  saveToLocalStorage () {
    if (!window.application.protocolModalOpened) { return }

    const storeState = FrontModal._sanitizeState(this.store.getState())
    const templateData = specSymbolsSanitize(JSON.stringify(storeState))
    const templateHtml = specSymbolsSanitize(FrontModal._sanitizeHtml(this.editorMountPoint.innerHTML), { noNbsp: true })
    const protocolId =
        +document.querySelector('#current_protocol_id')?.value || ProtocolDraftStorage.DEFAULT_PROTOCOL_ID

    new ProtocolDraftStorage(protocolId)
      .save({
        printingTools: this.currentPrintingToolsValues,
        templateHtml,
        templateData,
        semdType: gon.specific.__lastSemdType,
      })

    console.info('Черновик сохранён')
  }

  addButtons (editor) {
    super.addButtons(editor)

    editor.addButton('delete2', {
      icon: 'custom fad fa-trash',
      tooltip: t('delete_selection'),
      onclick: this.deleteContent.bind(this),
      onPostRender () {
        const ctrl = this
        editor.on('NodeChange', function (e) {
          const sel = tinymce.activeEditor.selection
          const content = sel.getContent()
          ctrl.disabled(content.length === 0)
        })
      },
    })
    editor.addButton('change_editor_margin', {
      icon: 'custom fad fa-exchange',
      tooltip: t('change_editor_position'),
      onclick: this.changeEditorPosition.bind(this),
    })
    editor.addButton('diagnoses', {
      icon: 'custom fad fa-users',
      classes: 'protocol-entities ',
      tooltip: t('diagnoses'),
      onclick: () => {
        this.buildCategory()
      },
    })

    editor.addButton(...toolbar3Params)
  }
}

import { EntityCollection } from 'libs/tree'

export default class ProtocolEntitiesModal {
  constructor (container) {
    this.container = container
    this.store = this.container.get('store')
    this.modal = ModalFactory({
      btnResize: false,
      title: t('select_suitable_categories'),
    })
    this.window = this.modal.find('.modal-body')
    this.window.append($(this.tpl))
    this.mountTreePoint = this.window.find('.mount-tree')
    this.saveButton = this.modal.find('.modal-save')
    // data-id in tinymce editor
    this.elementId = false
  }

  /**
   * base behaviour
   */
  show () {
    this.modal.megamodal('show')
  }

  /**
   * base behaviour
   */
  hide () {
    this.modal.megamodal('hide')
  }

  /**
   *  array tree - data for build tree
   *  elementId - data-id elements in editor
   */
  mount (tree, entity) {
    if (!entity) {
      Notificator.info(t('errors.entity_error'))

      return false
    }
    this.entity = entity
    this.container.get('entity_tree').build(
      tree,
      this.entity.data.categories,
      this.mountTreePoint
    )
  }

  save () {
    const selectedNodes = this.container.get('entity_tree').selectedNodes
    const itemCollection = new EntityCollection(selectedNodes)
    const ids = itemCollection.get('id')
    const cbFilter = (node) => !ids.includes(node.data.parent_id)
    const assignItemsIds = itemCollection.filter(cbFilter).get('id')

    if (assignItemsIds.length > 0) {
      this.store.dispatch({
        type: this.entity.actions.CHANGE,
        payload: {
          id: this.entity.id,
          data: assignItemsIds,
        },
      })
      this.hide()
    } else {
      Notificator.info(t('select_category'))
    }
  }

  get tpl () {
    return '<div class=\'mount-tree\'></div>'
  }
}

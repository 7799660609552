import BaseTree from '../base_tree'

export default class SelectMkbTree extends BaseTree {
  formatTree (data) {
    let result = []
    let root = {
      expanded: true,
      folder: true,
      data: { id: 0 },
      title: t('diagnoses'),
      children: [],
    }
    result.push(root)
    let tmp = [...data]
    while (data.length) {
      let title
      let element = data.shift(data)
      element.children = element.children || []
      element.folder = true
      element.lazy = true
      element.data = {
        id: element.id,
        type: 'category',
        key: 'c' + element.id,
      }
      if (element.parent_id === 0) {
        root.children.push(element)
      } else {
        element.title = `${element.info} ${element.title}`
        let el = tmp.find((item) => item.id === element.parent_id)
        el.children = el.children || []
        el.children.push(element)
      }
    }
    tmp.forEach((item) => {
      if (item.children.length === 0) {
        item.lazy = true
        delete (item.children)
      }
    })
    result.forEach((node) => this.straightExpand(node))

    return result
  }

  fancyData (data, lazy = false) {
    var res = []
    let store = res
    if (!lazy) {
      let root = { folder: true, data: { id: 0 }, title: t('diagnoses'), children: [] }
      res.push(root)
      store = root.children
    }
    data.catalogs.forEach(function (cat) {
      var el = {
        folder: true,
        title: cat.title,
        key: 'c' + cat.id,
        lazy: true,
        data: {
          id: cat.id,
          type: 'category',
        },
      }

      store.push(el)
    })

    data.items.forEach(function (et) {
      var el = {
        title: `<b>${et.code_string}</b> ${et.title}`,
        key: 'e' + et.id,
        data: {
          item: et,
          type: 'item',
        },
      }

      store.push(el)
    })

    return res
  }

  straightExpand (node, skipLazy = false) {
    if (!node.children && !skipLazy) {
      let result = this.container.get('api').diseases(node.data.id)
        .then((data) => {
          let res = this.fancyData(data, true)
          node.children = res
          $(this.mountPoint).fancytree()
        })
    }
    if (!node.children || node.children.length !== 1) return

    try {
      node.children[0].setExpanded(true)
    } catch (err) {
      node.children[0].expanded = true
    }
    this.straightExpand(node.children[0])
  }

  build (data, mountPoint, selectedCategories = false) {
    this.mountPoint = mountPoint
    let tree = this.formatTree(data)
    $(this.mountPoint).fancytree({
      checkbox: (event, data) => false,
      icon (event, data) {
        if (!data.node.folder) return false
      },
      source: tree,
      click: (event, data) => {
        const targetType = $.ui.fancytree.getEventTargetType(event.originalEvent)
        if (data.node.folder && targetType !== 'expander') {
          data.node.toggleExpanded()
        } else if (!data.node.folder) {
          let selectMkbModal = this.container.get('select_mkb_modal')
          selectMkbModal
            .addDisease
            .call(selectMkbModal, data.node.data.item)
        }
        if (data.node.folder) {
          this.straightExpand(data.node, true)
        }
      },
      selectMode: 2,
      select: this.selectNode.bind(this),
      lazyLoad: (e, data) => {
        let result = this.container.get('api').diseases(data.node.data.id)
          .then((data) => {
            let res = this.fancyData(data, true)

            return res
          })
        data.result = result
      },
    })
    this.tree.reload(tree)
    this._setConnectors()
  }

  /**
   * private
   */
  _postConstruct () {
    this._setMountPoint()
  }
}

import { DIAGNOSE } from '../../configuration/reducer_types'
import { EntityCollection } from 'libs/tree'

export default class SelectMkbModal {
  constructor (container) {
    this.container = container
    this.entityManager = this.container.get('entity_manager')
    this.tree = this.container.get('diseases_tree')
    this.modal = ModalFactory({
      btnResize: false,
      title: t('choose_diagnoses'),
    })
    this.modal[0].style.zIndex = 100000
    this.window = this.modal.find('.modal-body')
    this.window.append($(this.tpl))
    this.mountPoint = this.modal.find('.body-container')
    this.modalBody = this.window.find('.mount-body')
    this.saveButton = this.modal.find('.modal-save')
    this.saveButton.text(t('add_diagnoses'))
  }

  mountMkbSelector () {
    FormFactory.MKBSelector({
      model: 'templateRec',
      MKBSelector: {
        selector: $('#mkb_selector_test'),
        filterItems: (item) => this.availableCategoryIds.includes(item.category_id),
      },
    })
    PubSub.unsubscribe('page.form.templateRec.MKBSelector.selected')
    PubSub.on('page.form.templateRec.MKBSelector.selected', (msg, data) => {
      this.addDisease(data.item)
    })
  }

  mount (tree, emitter) {
    this.emitter = emitter
    this.availableCategoryIds = this.getAvailableCategoryIds(tree)
    this.container.get('select_mkb_tree').build(
      tree,
      this.mountPoint
    )
    this.saveButton.remove()
  }

  getAvailableCategoryIds (tree) {
    return tree.map((item) => item.id)
  }

  /**
   * base behaviour
   */
  show () {
    this.modal.megamodal('show')
    this.mountMkbSelector()
  }

  /**
   * base behaviour
   */
  hide () {
    this.modal.megamodal('hide')
  }

  save () {
    const selectedNodes = this.container.get('select_mkb_tree').selectedNodes
    const itemCollection = new EntityCollection(selectedNodes)
    const items = itemCollection.get('item')
    items.forEach((item) => this.addDisease(item))
    this.hide()
  }

  addDisease (item) {
    const diagnoses = this.container.get('store').getState().diagnoses
    const diagExists = diagnoses.find((e) => e.item.id === item.id)
    if (diagExists) {
      Notificator.info(`${T.diagnosis_already_exist}: ${item.title}`)

      return
    }
    const promise = new Promise((resolve, reject) => {
      const entity = this.entityManager
        .build(DIAGNOSE, { item, emitter: this.emitter })
      if (entity) {
        resolve(entity)
      }
    })
    promise.then((entity) => {
      entity.showValue()
      Notificator.info(`${I18n.t('added_diagnosis')} "${entity.data.item.title}".`)
      Services.pubSub.emit('frontendEditorListsChanged')
    })
  }

  get tpl () {
    return `<div class='body-container'>
      <div class="diagnosis_selector field-multiselector" id="mkb_selector_test">
        <div class="flex-container flex-item hidden-print">
          <input class="form-control search diagnoses-selector__input" type="text" placeholder="Введите код или название диагноза" />
        </div>
      </div>
    </div>`
  }
}

import { createVueApp } from '@/vue_components/create_vue_app'
import SemdProtocolEditorApp from '@/vue_apps/Protocols/SemdProtocolEditor/SemdProtocolEditorApp.vue'

const vueApp = {}

export const createSemdApp = (el, editor) => {
  const editorId = editor.id

  if (vueApp[editorId]?.$destroy) { vueApp[editorId].$destroy() }

  if (!el) { return }
  if (!document.querySelector(el)) { return }

  vueApp[editorId] = createVueApp({
    el,
    name: 'SemdAppRoot',
    render: (h) => h(SemdProtocolEditorApp, {
      props: {
        editor,
      },
    }),
  })
}

import { getDefaultGlobalDiscount } from '@/forms/components/order/entry_list'

/**
 * Костыль позволяющий обходить ограничения payer
 * и обновлять discount_value у оригинальной энтри
 */
export const resetDiscount = (payer) => {
  setTimeout(() => {
    const { discountValue } = getDefaultGlobalDiscount(payer)

    const $discountContainers = $('.discount-container')
    $discountContainers.each((i, e) => {
      const $dataDiscountTypePercent = $(e).find('[data-discount-type="percent"]')
      const $discountValue = $(e).find('.f-order-entry-discount-value[readonly!="readonly"]')

      const discount = parseFloat($discountValue.val())
      if (discount >= discountValue) { return }

      $dataDiscountTypePercent.trigger('click')
      $discountValue
        .val(Utils.toMoney(discountValue))
        .trigger('change')
    })
  }, 0)
}

import baseTemplateComponent from '../base_component'

import isArray from 'lodash/isArray'
import trim from 'lodash/trim'
import last from 'lodash/last'

export default class ListEditor extends baseTemplateComponent {
  constructor (params, builder) {
    super(params, builder)
    this._buildModal({ fullscreenButton: false })
    this._bindEvents()
  }

  show (entity) {
    this.entity = entity
    this.actions = this.entity.actions
    this._activeListType = entity.type
    this._activeNode = entity.element
    this._activeListId = entity.id
    const list = this.entity.data
    const values = this._getListValues(list)
    this._prepareModal(list, values)
    this._window.megamodal('show')
  }

  _prepareModal ({ defaultValue, name }, values) {
    const string = values.map((group) => group.join('\n')).join('\n\n')
    let formattedDefaultValue
    if (defaultValue && defaultValue.length) {
      formattedDefaultValue = defaultValue.replace(/\uFEFF/g, '')
    }
    const defaultLine = string
      .split('\n')
      .findIndex((l) => l === formattedDefaultValue) + 1

    this._textarea.val(string)
    this._window[0].querySelector('.list-modal-name').innerText = name

    this._defaultLineSelector.val('')
    if (defaultLine) this._defaultLineSelector.val(defaultLine)

    this._listTypeSelector.val(this._activeListType)
    this._clearValidationErrors()
  }

  _getListValues (list) {
    let values = list.value
    if (!isArray(values)) values = []
    // may be useless legacy form conversion (move everything in first group)
    if (!values.every((e) => isArray(e))) values = [values]

    return values
  }

  _buildModal () {
    this._window = ModalFactory()
    this._windowBody = this._window.find('.modal-body')

    const formGroup = $('<div>').addClass('form-group')
    const controlBlock = this._buildControlBlock()

    this._textarea = $('<textarea>')
      .addClass('list-editor-area')
      .appendTo(formGroup)

    this._windowBody.append(controlBlock)
    this._windowBody.append(formGroup)

    this._textarea.setTextareaCount()
    this._window.megamodal({ btnResize: false })
  }

  _buildControlBlock () {
    this._controlForm = $(`
    <form class="form-inline list-editor-control">
      <div class="form-group list-editor-default-container">
        <b class='list-modal-name'></b>
        <label>${t('default_string')}:</label>
        <input type="number" min="0" class="default-line form-control">
      </div>
      <div class="form-group list-editor-type-container">
        <label>${t('type')}:</label>
        <select class="list-type form-control">
          <option value='fix_list'>${t('fix_list')}</option>
          <option value='ext_list'>${t('open_list')}</option>
        </select>
      </div>
    </form>
    `)

    this._defaultLineSelector = this._controlForm.find('.default-line')
    this._listTypeSelector = this._controlForm.find('.list-type')

    return $(this._controlForm)
  }

  _saveHandler () {
    this._clearValidationErrors()
    if (!this._validate()) return

    const rawText = this._textarea.val()
    const normalizedText = rawText.replace(/\n[\s\n]*\n/g, '\n\n')

    const defaultLine = parseInt(this._defaultLineSelector.val()) - 1
    const defaultValue = rawText.split('\n')[defaultLine]

    const values = normalizedText.split('\n\n').map((s) => s.split('\n'))

    if (this._isListTypeChanged()) {
      this._saveWithAnotherType(values, defaultValue)
    } else {
      this._saveWithTheSameType(values, defaultValue)
    }

    this._window.megamodal('hide')
  }

  _saveWithTheSameType (values, defaultValue) {
    this.store.dispatch({
      type: this.actions.CHANGE,
      payload: {
        id: this._activeListId,
        value: values,
        defaultValue,
      },
    })
  }

  _isListTypeChanged () {
    return this._activeListType !== this._listTypeSelector.val()
  }

  _saveWithAnotherType (values, defaultValue) {
    const newType = this._listTypeSelector.val()
    const oldType = this._activeListType

    const newEntityConf = this.config[newType]
    const oldEntityConf = this.config[oldType]

    const actionAdd = newEntityConf.actions.ADD
    const actionChange = newEntityConf.actions.CHANGE
    const actionDelete = oldEntityConf.actions.DELETE

    // create new element
    this.store.dispatch({
      type: actionAdd,
      payload: { title: newEntityConf.title, alias: newEntityConf.alias },
    })

    const newList = last(this.store.getState()[newEntityConf.dataType])
    const oldId = this._activeListId

    // copy data to new element
    this.store.dispatch({
      type: actionChange,
      payload: {
        id: newList.id,
        value: values,
        defaultValue,
      },
    })

    // drop old element
    this.store.dispatch({ type: actionDelete, payload: { id: oldId } })

    // not sure about this stuff
    Object.assign(this._activeNode.dataset, {
      name: newList.name,
      id: newList.id,
      type: newType,
    })
    const cssClassesMap = [newEntityConf.dataType, oldEntityConf.dataType]
    const newContent = newList.name
    $(this._activeNode)
      .removeClass(cssClassesMap.join(' '))
      .addClass(newType)
      .attr({
        'data-name': newList.name,
        'data-id': newList.id,
        'data-type': newEntityConf.dataType,
      })
      .html(newContent)
  }

  _validate () {
    const lines = this._textarea.val().split('\n')
    const defaultLine = parseInt(this._defaultLineSelector.val())
    const defaultLineIndex = defaultLine - 1

    if (defaultLine && !trim(lines[defaultLineIndex])) {
      this._addErrorToField(this._defaultLineSelector, t('incorrect_value'))

      return false
    }

    return true
  }

  _addErrorToField (field, txt) {
    const container = field.closest('.form-group')
    container.addClass('has-error has-feedback')
    const error = $('<div>').addClass('under_field_error').text(txt)
    container.append(error)
  }

  _clearValidationErrors () {
    this._controlForm.find('.has-error').each((i, container) => {
      container = $(container)
      container.removeClass('has-error has-feedback')
      container.find('.under_field_error').remove()
    })
  }

  _bindEvents () {
    this._window.find('.modal-save').on('click', this._saveHandler.bind(this))

    this._window.on('show.bs.modal', () => this._windowBody.loadSpin('start'))
    this._window.on('shown.bs.modal', () => {
      this._textarea.setTextareaCount('reset')
      this._windowBody.loadSpin('stop')
    })

    this._controlForm.on('submit', (e) => e.preventDefault())
  }
}
